const premiumWidgetsCategoryData = {
  mentalStatusExam: {
    extras: {},
    generalAppearance: {
      casuallyDressed: false,
      wellGroomed: false,
      wellNourished: false,
      disheveled: false,
      poorHygiene: false,
      overweight: false,
      thin: false,
      eccentricAttire: false,
      others: false,
      others_field: "",
    },
    attitudeBehavior: {
      cooperative: false,
      uncooperative: false,
      apathetic: false,
      defensive: false,
      evasive: false,
      guarded: false,
      oppositional: false,
      goodEyeContact: false,
      poorEyeContact: false,
      others: false,
      others_field: "",
    },
    sensorium: {
      clear: false,
      impaired: false,
      others: false,
      others_field: "",
    },
    psychomotor: {
      normal_age: false,
      hyperactive: false,
      eps: false,
      agitated: false,
      retarded: false,
      hyperkinetic: false,
      abnormalMovement: false,
      tremors: false,
      motorTics: false,
      vocalTics: false,
      stereotypies: false,
      others: false,
      others_field: "",
    },
    mood: {
      neutral: false,
      happy: false,
      fine: false,
      dontKnow: false,
      depressed: false,
      anxious: false,
      irritable: false,
      angry: false,
      great: false,
      ok: false,
      noAnswer: false,
      others: false,
      others_field: "",
    },
    affect: {
      euthymic: false,
      moodCongruent: false,
      moodIncongruent: false,
      flat: false,
      constricted: false,
      blunted: false,
      labile: false,
      others: false,
      others_field: "",
    },
    speechLanguage: {
      normal_rate: false,
      slow: false,
      soft: false,
      loud: false,
      hyperverbal: false,
      rapid: false,
      pressured: false,
      delayed: false,
      selectivelyMute: false,
      stuttering: false,
      others: false,
      others_field: "",
    },
    thoughtProcesses: {
      linearGoalDirected: false,
      circumstantial: false,
      tangential: false,
      retarded: false,
      looseAssociation: false,
      flightOfIdeas: false,
      blocking: false,
      perseverations: false,
      disorganized: false,
      others: false,
      others_field: "",
    },
    thoughtContent: {
      realityOriented: false,
      urgesToSelfInjure: false,
      hopeless: false,
      poorBodyImage: false,
      delusions: false,
      impoverished: false,
      obsessional: false,
      povertyOfContent: false,
      others: false,
      others_field: "",
      description: "",
    },
    perceptions: {
      noPerceptionIssues: false,
      auditoryHallucinations: false,
      visualHallucinations: false,
      illusions: false,
      others: false,
      others_field: "",
      description: "",
    },
    sucidialThoughts: {
      sucidialThoughtNo: false,
      sucidialThoughtYes: false,
      sucidialThoughtInput: "",
      frequencyInput: "",
      planInput: "",
      intentInput: "",
      activePassiveInput: "",
      chronicOrAcuteInput: "",
      meansInput: "",
    },
    homicidalThoughts: {
      homicidalThoughtsNo: false,
      homicidalThoughtsYes: false,
      description: "",
    },
    capacityOfAdls: {
      independent: false,
      someAssistance: false,
      dependent: false,
    },
    orientations: {
      person: false,
      place: false,
      date: false,
      time: false,
      situation: false,
    },
    worldSpell: {
      DInput: "",
      LInput: "",
      RInput: "",
      OInput: "",
      WInput: "",
    },
    memoryImmediate: {
      two: "",
      six: "",
      eight: "",
      nine: "",
      three: "",
      one: "",
      five: "",
    },
    concentrationAndAttention: {
      intact: false,
      impairedConcentration: false,
      impairedAttentation: false,
    },
    insightGood: {
      good: false,
      limited: false,
      poor: false,
      others_field: "",
      others: false,
    },
    judgment: {
      good: false,
      limited: false,
      poor: false,
      others_field: "",
      others: false,
    },
    intelligence: {
      aboveAverage: false,
      average: false,
      belowAverage: false,
      others_field: "",
      others: false,
    },
    memoryRecent: {
      lamp: false,
      umberella: false,
      telephone: false,
    },
    memoryRemote: {
      remoteInput: "",
    },
    patientAssessment: {
      intact: false,
      impairedImmediate: false,
      impairedRecent: false,
      impairedRemote: false,
    },
    concentration: {
      developmentallyAppropriate: false,
      mildDistractibility: false,
      severeDistractibility: false,
    },
    fundOfKnowledge: {
      developmentallyAppropriate: false,
      average: false,
      aboveAverage: false,
      belowAverage: false,
      others: false,
      others_field: "",
    },
    memory: {
      developmentallyAppropriate: false,
      mildImpairment: false,
      moderateImpairment: false,
      severeImpairment: false,
    },
  },
  psychROS: {
    extras: {
      elaborate: "",
    },
    depressive: {
      depressedMood: false,
      sleepDisturbance: false,
      lossOfInterestInPleasurableActivities: false,
      feelingOfGuiltOrWorthlessness: false,
      lowEnergyOrFatigue: false,
      problemsWithConcentration: false,
      weightOrAppetiteChangesLossOrGain: false,
      appetiteChanges: false,
      psychomotorRetardationActivation: false,
      suicidalIdeation: false,
    },
    psychotic: {
      hallucinations: false,
      delusions: false,
      disorganizedSpeechOrThoughts: false,
      disorganizedOrCatatonicBehavior: false,
      lackOfEmotionsOrFacialExpression: false,
      lackOfInterestInActivitiesOrSocialEngagement: false,
      poorHygeine: false,
      lackOfEnergy: false,
    },
    disruptiveMoodDysregulation: {
      persistentIrritableOrAngryMood: false,
      inappropriateTemperOutbursts: false,
    },
    manicOrHypomanic: {
      euphoricoodOrExcessiveIrritabilityWithLittleProvocation: false,
      ideasOfGrandeurOrInflatedSelfesteen: false,
      decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep: false,
      pressuredSpeechOrHyperverbal: false,
      racingThoughtsOrFlightOfIdeas: false,
      easilyDistractedOnUnimportantOrIrrelevantActivities: false,
      increasedGoalDirectedActivitiesOrPsychomotorAgitation: false,
      increasedActivityWithHigherPotentialForHarmfulConsequences: false,
    },
    personalityDisorder: {
      instabilityInEmotionsRelationshipsOrSelfImage: false,
      needToBeTheCenterOfAttention: false,
      feelingNoRemorseWithOnesBehaviorThatViolateOthers: false,
      obsessionWithSuccessPowerBeautyOrLove: false,
    },
    neurocognitive: {
      cognitiveDeclineInLearningAndMemory: false,
      languageComprehensionAndProductionIssues: false,
      executiveFunctioningDecline: false,
      complexAttentionDeficiency: false,
      perceptualMotorDecline: false,
      socialCognitionDecline: false,
    },
    aDHD: {
      persistentLackOfAttentionToDetail: false,
      troubleStayingFocused: false,
      difficultyFollowingInstructions: false,
      easyDistractibility: false,
      forgetfulness: false,
      oftenMisplacingPossessions: false,
      difficultySustainingMentalEffort: false,
      fidgetiness: false,
      restlessness: false,
      oftenTalksExcessively: false,
      blurtsOutAnswers: false,
      interruptsOthers: false,
      hasTroubleWaitingtheirTurn: false,
      symptomsPresentBeforeAge12: false,
    },
    anxiety: {
      excessiveAnxietiesAndWorries: false,
      restlessness: false,
      difficultyConcentrating: false,
      irritability: false,
      muscleTension: false,
      disruptedSleep: false,
      fearOfSocialInteractions: false,
      obsessions: false,
      compulsions: false,
      ruminations: false,
      fearOfBeingJudgedInSocialSituations: false,
      fearOfOpenSpaces: false,
      fearOfSpecificthings: false,
      panicAttacks: false,
      fearAndDistress: false,
    },
    anxietyAdol: {
      excessiveAnxietiesAndWorries: false,
      restlessness: false,
      difficultyConcentrating: false,
      irritability: false,
      muscleTension: false,
      disruptedSleep: false,
      fearOfSocialInteractions: false,
      obsessions: false,
      compulsions: false,
      ruminations: false,
      fearOfBeingJudgedInSocialSituations: false,
      fearOfOpenSpaces: false,
      fearOfSpecificthings: false,
      panicAttacks: false,
      fearAndDistress: false,
    },
    panicAttack: {
      palpitationsTachycardia: false,
      diaphoresis: false,
      tremulousness: false,
      shortnessOfBreath: false,
      chokingSensation: false,
      chestPainDiscomfort: false,
      nauseaAbdominalDistress: false,
      dizzyLightheadedFaintOrUnsteady: false,
      derealizationOrDepersonalization: false,
      fearOfLosingControl: false,
      impendingSenseOfDoomOrDying: false,
      paresthesias: false,
      chillsOrHeatSensations: false,
      avoidanceOfActivitiesForFearOfAnotherEpisode: false,
    },
    otherBehavioral: {
      disobedientArgumentativeDefiant: false,
      questioningOrRefusalToFollowRules: false,
      blamingOthers: false,
      unkindVindictive: false,
      violatesBasicRightsOfOthersOrNormsRules: false,
      aggressionToPeopleAndAnimals: false,
      destructionOfProperty: false,
      deceitfulOrEngagingInTheftActivities: false,
    },
    autism: {
      deficitsInSocialEmotionalReciprocity: false,
      deficitsInNonverbalCommunicationBehaviors: false,
      deficitsInDevelopingMaintainingAndUnderstandingRelationships: false,
    },
    pTSD: {
      exposureToDeath: false,
      traumaIsOftenReExperienced: false,
      traumaAssociatedTriggers: false,
      associatedNegativeThoughts: false,
      associatedArousalAndReactivitySymptoms: false,
    },
  },
};
export function transformPremiumWidgetData(obj, dataObject = null) {
  const transformedObject = {};
  const isPsychROS = ["psychROSComp", "psychRosComp"].includes(obj["type"]);

  for (const key in obj) {
    if (key.endsWith("Symptoms")) {
      const category = key.replace("Symptoms", "");
      const categoryCheckKey = isPsychROS ? `${category}SymptomsCheck` : `${category}Check`;

      if (dataObject && dataObject[key] && obj[key] == true) {
        transformedObject[key] = dataObject[key];
      } else {
        transformedObject[key] = getcategoryDefualtValues(category, categoryCheckKey, obj[key], isPsychROS ? "psychROS" : obj["type"].replace("Comp", "") );
        if (isPsychROS && category == "anxietyAdol" && obj[key] == true) {
          transformedObject[key]["childAdolCheck"] = true;
        }
      }
    }
  }

  return transformedObject;
}
function getcategoryDefualtValues(
  category,
  categoryCheckKey,
  categoryCheck = false,
  objectType = null
) {
  return {
    [categoryCheckKey]: categoryCheck,
    ...premiumWidgetsCategoryData[objectType][category],
    ...premiumWidgetsCategoryData[objectType]["extras"],
  };
}
