import React from "react";
import PropTypes from "prop-types"
import { FormBuilder, Components, Form } from "@formio/react";
import components from "./Custom";

Components.setComponents(components);

class CustomMedication extends React.Component {

  render () {

    var form_json_data = JSON.parse(this.props.form_data);

    return (
      <div className="App">
        <Form form={form_json_data} onSubmit={console.log} />
      </div>
    );
  }
}

CustomMedication.propTypes = {
  form_data: PropTypes.string
};

export default CustomMedication
