import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Goals.settingsForm";
import AchievedGoalsImage from 'images/widget/AchievedGoals.png';
import ActiveGoalsImage from 'images/widget/ActiveGoals.png';
import DeferredGoalsImage from 'images/widget/DefferedGoals.png';
import DiscontinuedGoalsImage from 'images/widget/DiscontinedGoals.png';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const GoalsComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      
      goals: {
        activeGoals: {
          widget_data: [],
        },
        goalsRadioButton: props.component.goalsRadioButton || "",
        progressTowardsGoal: {
          radioValue: "",
        },
      },
    };
    this.props.onChange(this.state.goals);
  }
  handleRadioButtonChange = (event) => {
    const { name, value } = event.target;
    const section = name.split(".")[0];
    const key = name.split(".")[1];

    this.setState(
      (prevState) => ({
        goals: {
          ...prevState.goals,
          [section]: {
            ...prevState.goals[section],
            [key]: value,
          },
        },
      }),
      () => {
        this.props.onChange(this.state.goals);
      }
    );
  };
  getValue() {
    return this.props.goals;
  }
  setValue(value) {
    if (value !== null) {
      console.log("SetValue", value)

      this.setState({
        goals: {
          activeGoals: {
            widget_data: [],
          },
          goalsRadioButton: value.goalsRadioButton,
          progressTowardsGoal: {
            progressTowardsGoal:
              value.progressTowardsGoal.progressTowardsGoalCheck,
            radioValue: value.progressTowardsGoal.radioValue,
          },
        },
      });
    }
  }

  openEditModal = (data) => {
    if(data.item.can_edit){
       $.post('/form_builders/widgets/new_edit_sb_attr', { 
        attr_type: 'reg_allergy',
        patient_guid: data.item.patient_guid,
        id: data.item.widgetable_id,
        patient_form_guid: data.item.patient_form_guid,
        patient_form_entry_guid: data.item.guid
      }); 
    }   
  }

  openCreateModal = () =>{
   $.post('/form_builders/widgets/new_edit_sb_attr', {
    attr_type: 'reg_allergy',
    patient_guid: $('#patient_guid').val(), patient_form_guid: $('#patient_note_guid').val() });
  }

  render() {
    $(".component-edit-container p.lead").html("Goals Widget");
    console.log("GoalsComp", this)
    return (
      <div id={this.state.component.key}>
        {/* ActiveGoalsComp */}

        <div
          className={`${
            this.state.component.goalsRadioButton == "1"
              ? "active-goals-widget show"
              : "d-none"
          }`}
        >
          <img className="img-fluid" src={ActiveGoalsImage} />
        </div>

        {/* AchievedGoals */}

        <div
          className={`${
            this.state.component.goalsRadioButton == "2"
              ? "achieved-goals-widget show"
              : "d-none"
          }`}
        >
          <img className="img-fluid" src={AchievedGoalsImage} />
        </div>

        {/* RevisedGoalsComp */}

        <div
          className={`${
            this.state.component.goalsRadioButton == "3"
              ? "revised-goals-widget show"
              : "d-none"
          }`}
        >
          <img className="img-fluid" src={DeferredGoalsImage} />
        </div>

        {/* DiscontinuedGoalsComp */}

        <div
          className={`${
            this.state.component.goalsRadioButton == "4"
              ? "discontinued-goals-widget show"
              : "d-none"
          }`}
        >
          <img className="img-fluid" src={DiscontinuedGoalsImage} />
        </div>

        {/* DeferredGoalsComp */}

        <div
          className={`${
            this.state.component.goalsRadioButton == "5"
              ? "deferred-goals-widget show"
              : "d-none"
          }`}
        >
          <img className="img-fluid" src={DeferredGoalsImage} />
        </div>

        {/* ProgressTowardsGoalsComp */}
        <div
          className={`progressTowardsGoalsComp ${
            this.state.component.goalsRadioButton == "6" ? "show" : "d-none"
          }`}
        >
          <table className="table table-bordered goals-table w-100">
            <tbody>
              <tr>
                <td className="bg-light-grey" width="30%">
                  Progress Towards Goals
                </td>
                <td>
                  <select
                    className="form-control"
                    name="progressTowardsGoal.radioValue"
                    value={this.state.goals.progressTowardsGoal.radioValue}
                    onChange={this.handleRadioButtonChange}
                  >
                    <option disabled>Select</option>
                    <option value="Regressed">Regressed</option>
                    <option value="No Progress">No Progress</option>
                    <option value="Minor Progress">Minor Progress</option>
                    <option value="Moderate">Moderate</option>
                    <option value="Significant Progress">
                      Significant Progress
                    </option>
                    <option value="Goal Achieved">Goal Achieved</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default class Goals extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Goals",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Goals.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "goalsComp",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <GoalsComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
