import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./MentalStatusExam.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const MentalStatusExamComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      mentalExam: {
        generalAppearance: {
          generalAppearanceCheck: props.component.generalAppearance,
          casuallyDressed: false,
          wellGroomed: false,
          wellNourished: false,
          disheveled: false,
          poorHygiene: false,
          overweight: false,
          thin: false,
          eccentricAttire: false,
          others: false,
          others_field: "",
        },
        attitudeBehavior: {
          attitudeBehaviorCheck: props.component.attitudeBehavior,
          cooperative: false,
          uncooperative: false,
          apathetic: false,
          defensive: false,
          evasive: false,
          guarded: false,
          oppositional: false,
          goodEyeContact: false,
          poorEyeContact: false,
          others: false,
          others_field: "",
        },
        sensorium: {
          sensoriumCheck: props.component.sensorium,
          clear: false,
          impaired: false,
          others: false,
          others_field: "",
        },
        psychomotor: {
          psychomotorCheck: props.component.psychomotor,
          normal_age: false,
          hyperactive: false,
          eps: false,
          agitated: false,
          retarded: false,
          hyperkinetic: false,
          abnormalMovement: false,
          tremors: false,
          motorTics: false,
          vocalTics: false,
          stereotypies: false,
          others: false,
          others_field: "",
        },
        mood: {
          moodCheck: props.component.mood,
          neutral: false,
          happy: false,
          fine: false,
          dontKnow: false,
          depressed: false,
          anxious: false,
          irritable: false,
          angry: false,
          great: false,
          ok: false,
          noAnswer: false,
          others: false,
          others_field: "",
        },
        affect: {
          affectCheck: props.component.affect,
          euthymic: false,
          moodCongruent: false,
          moodIncongruent: false,
          flat: false,
          constricted: false,
          blunted: false,
          labile: false,
          others: false,
          others_field: "",
        },
        speechLanguage: {
          speechLanguageCheck: props.component.speechLanguage,
          normal_rate: false,
          slow: false,
          soft: false,
          loud: false,
          hyperverbal: false,
          rapid: false,
          pressured: false,
          delayed: false,
          selectivelyMute: false,
          stuttering: false,
          others: false,
          others_field: "",
        },
        thoughtProcesses: {
          thoughtProcessesCheck: props.component.thoughtProcesses,
          linearGoalDirected: false,
          circumstantial: false,
          tangential: false,
          retarded: false,
          looseAssociation: false,
          flightOfIdeas: false,
          blocking: false,
          perseverations: false,
          disorganized: false,
          others: false,
          others_field: "",
        },
        thoughtContent: {
          thoughtContentCheck: props.component.thoughtContent,
          realityOriented: false,
          urgesToSelfInjure: false,
          hopeless: false,
          poorBodyImage: false,
          delusions: false,
          impoverished: false,
          obsessional: false,
          povertyOfContent: false,
          others: false,
          others_field: "",
          description: "",
        },
        perceptions: {
          perceptionsCheck: props.component.perceptions,
          noPerceptionIssues: false,
          auditoryHallucinations: false,
          visualHallucinations: false,
          illusions: false,
          others: false,
          others_field: "",
          description: "",
        },
        sucidialThoughts: {
          sucidialThoughtsCheck: props.component.sucidialThoughts,
          sucidialThoughtNo: false,
          sucidialThoughtYes: false,
          sucidialThoughtInput: "",
          frequencyInput: "",
          planInput: "",
          intentInput: "",
          activePassiveInput: "",
          chronicOrAcuteInput: "",
          meansInput: "",
        },
        homicidalThoughts: {
          homicidalThoughtsCheck: props.component.homicidalThoughts,
          homicidalThoughtsNo: false,
          homicidalThoughtsYes: false,
          description: "",
        },
        capacityOfAdls: {
          capacityOfAdlsCheck: props.component.capacityOfAdls,
          independent: false,
          someAssistance: false,
          dependent: false,
        },
        orientations: {
          orientationsCheck: props.component.orientations,
          person: false,
          place: false,
          date: false,
          time: false,
          situation: false,
        },
        worldSpell: {
          worldSpellCheck: props.component.worldSpell,
          DInput: "",
          LInput: "",
          RInput: "",
          OInput: "",
          WInput: "",
        },
        memoryImmediate: {
          memoryImmediateCheck: props.component.memoryImmediate,
          two: "",
          six: "",
          eight: "",
          nine: "",
          three: "",
          one: "",
          five: "",
        },
        concentrationAndAttention: {
          concentrationAndAttentionCheck:
            props.component.concentrationAndAttention,
          intact: false,
          impairedConcentration: false,
          impairedAttentation: false,
        },
        insightGood: {
          insightGoodCheck: props.component.insightGood,
          good: false,
          limited: false,
          poor: false,
          others_field: "",
          others: false,
        },
        judgment: {
          judgmentCheck: props.component.judgment,
          good: false,
          limited: false,
          poor: false,
          others_field: "",
          others: false,
        },
        intelligence: {
          intelligenceCheck: props.component.judgment,
          aboveAverage: false,
          average: false,
          belowAverage: false,
          others_field: "",
          others: false,
        },
        memoryRecent: {
          memoryRecentCheck: props.component.memoryRecent,
          lamp: false,
          umberella: false,
          telephone: false,
        },
        memoryRemote: {
          memoryRemote: props.component.memoryRemote,
          remoteInput: "",
        },
        patientAssessment: {
          patientAssessmentCheck: props.component.patientAssessment,
          intact: false,
          impairedImmediate: false,
          impairedRecent: false,
          impairedRemote: false,
        },
        concentration: {
          concentrationCheck: props.component.concentration,
          developmentallyAppropriate: false,
          mildDistractibility: false,
          severeDistractibility: false,
        },
        fundOfKnowledge: {
          fundOfKnowledgeCheck: props.component.fundOfKnowledge,
          developmentallyAppropriate: false,
          average: false,
          aboveAverage: false,
          belowAverage: false,
          others: false,
          others_field: "",
        },
        memory: {
          memoryCheck: props.component.memory,
          developmentallyAppropriate: false,
          mildImpairment: false,
          moderateImpairment: false,
          severeImpairment: false,
        },
      },
    };

    this.props.onChange(this.state.mentalExam);
  }

  handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const section = name.split(".")[0];
    const key = name.split(".")[1];

    this.setState(
      (prevState) => ({
        mentalExam: {
          ...prevState.mentalExam,
          [section]: {
            ...prevState.mentalExam[section],
            [key]: !prevState.mentalExam[section][key],
          },
        },
      }),
      () => {
        this.props.onChange(this.state.mentalExam);
      }
    );
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    const section = name.split(".")[0];
    const key = name.split(".")[1];

    this.setState(
      (prevState) => ({
        mentalExam: {
          ...prevState.mentalExam,
          [section]: {
            ...prevState.mentalExam[section],
            [key]: value,
          },
        },
      }),
      () => {
        this.props.onChange(this.state.mentalExam);
      }
    );
  };

  getValue() {
    return this.state.mentalExam;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        mentalExam: {
          generalAppearance: value.generalAppearance,
          attitudeBehavior: value.attitudeBehavior,
          sensorium: value.sensorium,
          psychomotor: value.psychomotor,
          mood: value.mood,
          affect: value.affect,
          speechLanguage: value.speechLanguage,
          thoughtProcesses: value.thoughtProcesses,
          thoughtContent: value.thoughtContent,
          perceptions: value.perceptions,
          sucidialThoughts: value.sucidialThoughts,
          homicidalThoughts: value.homicidalThoughts,
          capacityOfAdls: value.capacityOfAdls,
          orientations: value.orientations,
          concentrationAndAttention: value.concentrationAndAttention,
          worldSpell: value.worldSpell,
          memoryImmediate: value.memoryImmediate,
          insightGood: value.insightGood,
          judgment: value.judgment,
          intelligence: value.intelligence,
          memoryRecent: value.memoryRecent,
          memoryRemote: value.memoryRemote,
          patientAssessment: value.patientAssessment,
          concentration: value.concentration,
          fundOfKnowledge: value.fundOfKnowledge,
          memory: value.memory,
        },
      });
    }
  }

  render() {
        $(".component-edit-container p.lead").html("Mental Status Exam Widget");
    return (
      <div id={this.state.component.key}>
        <div
          className="container-fluid mental-exam-widget"
          id="mentalStatusExam"
        >
          <div className="row">
            <div className="col-12 text-center my-1">
              <label>
                <h2 className="bg-lightgray p-2 bold">Mental Status Exam</h2>
              </label>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-md-12 ${
                this.state.component.generalAppearance ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>General Appearance</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="casuallyDressed"
                      name="generalAppearance.casuallyDressed"
                      value={
                        this.state.mentalExam.generalAppearance.casuallyDressed
                      }
                      checked={
                        this.state.mentalExam.generalAppearance.casuallyDressed
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="casuallyDressed">
                      Casually Dressed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.wellGroomed"
                      id="wellGroomed"
                      value={
                        this.state.mentalExam.generalAppearance.wellGroomed
                      }
                      checked={
                        this.state.mentalExam.generalAppearance.wellGroomed
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="wellGroomed">
                      Well groomed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.wellNourished"
                      id="wellNourished"
                      value={
                        this.state.mentalExam.generalAppearance.wellNourished
                      }
                      checked={
                        this.state.mentalExam.generalAppearance.wellNourished
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="wellNourished">
                      Well Nourished
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.disheveled"
                      id="disheveled"
                      value={this.state.mentalExam.generalAppearance.disheveled}
                      checked={
                        this.state.mentalExam.generalAppearance.disheveled
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="disheveled">
                      Disheveled
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.poorHygiene"
                      id="poorHygiene"
                      value={
                        this.state.mentalExam.generalAppearance.poorHygiene
                      }
                      checked={
                        this.state.mentalExam.generalAppearance.poorHygiene
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="poorHygiene">
                      Poor hygiene
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.overweight"
                      id="overweight"
                      value={this.state.mentalExam.generalAppearance.overweight}
                      checked={
                        this.state.mentalExam.generalAppearance.overweight
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="overweight">
                      Overweight
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.thin"
                      id="thin"
                      value={this.state.mentalExam.generalAppearance.thin}
                      checked={this.state.mentalExam.generalAppearance.thin}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="thin">
                      Thin
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.eccentricAttire"
                      id="eccentricAttire"
                      value={
                        this.state.mentalExam.generalAppearance.eccentricAttire
                      }
                      checked={
                        this.state.mentalExam.generalAppearance.eccentricAttire
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="eccentricAttire">
                      Eccentric Attire
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="generalAppearance.others"
                      id="generalAppearance.others"
                      value={this.state.mentalExam.generalAppearance.others}
                      checked={this.state.mentalExam.generalAppearance.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="generalAppearance.others"
                    >
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="generalAppearance.others_field"
                      id="generalAppearance.others_field"
                      value={
                        this.state.mentalExam.generalAppearance.others_field
                      }
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.attitudeBehavior ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Attitude and Behavior</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.cooperative"
                      id="cooperative"
                      value={this.state.mentalExam.attitudeBehavior.cooperative}
                      checked={
                        this.state.mentalExam.attitudeBehavior.cooperative
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="cooperative">
                      Cooperative
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.uncooperative"
                      id="uncooperative"
                      value={
                        this.state.mentalExam.attitudeBehavior.uncooperative
                      }
                      checked={
                        this.state.mentalExam.attitudeBehavior.uncooperative
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="uncooperative">
                      Uncooperative
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.apathetic"
                      id="apathetic"
                      value={this.state.mentalExam.attitudeBehavior.apathetic}
                      checked={this.state.mentalExam.attitudeBehavior.apathetic}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="apathetic">
                      Apathetic
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.defensive"
                      id="defensive"
                      value={this.state.mentalExam.attitudeBehavior.defensive}
                      checked={this.state.mentalExam.attitudeBehavior.defensive}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="defensive">
                      Defensive
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.evasive"
                      id="evasive"
                      value={this.state.mentalExam.attitudeBehavior.evasive}
                      checked={this.state.mentalExam.attitudeBehavior.evasive}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="evasive">
                      Evasive
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.guarded"
                      id="guarded"
                      value={this.state.mentalExam.attitudeBehavior.guarded}
                      checked={this.state.mentalExam.attitudeBehavior.guarded}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="guarded">
                      Guarded
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.oppositional"
                      id="oppositional"
                      value={
                        this.state.mentalExam.attitudeBehavior.oppositional
                      }
                      checked={
                        this.state.mentalExam.attitudeBehavior.oppositional
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="oppositional">
                      Oppositional
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.goodEyeContact"
                      id="goodEyeContact"
                      value={
                        this.state.mentalExam.attitudeBehavior.goodEyeContact
                      }
                      checked={
                        this.state.mentalExam.attitudeBehavior.goodEyeContact
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="goodEyeContact">
                      Good Eye Contact
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.poorEyeContact"
                      id="poorEyeContact"
                      value={
                        this.state.mentalExam.attitudeBehavior.poorEyeContact
                      }
                      checked={
                        this.state.mentalExam.attitudeBehavior.poorEyeContact
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="poorEyeContact">
                      Poor Eye Contact
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="attitudeBehavior.others"
                      id="attitudeBehavior.others"
                      value={this.state.mentalExam.attitudeBehavior.others}
                      checked={this.state.mentalExam.attitudeBehavior.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="attitudeBehavior.others"
                    >
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="attitudeBehavior.others_field"
                      id="attitudeBehavior.others_field"
                      value={
                        this.state.mentalExam.attitudeBehavior.others_field
                      }
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.sensorium ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Sensorium</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sensorium.clear"
                      id="clear"
                      value={this.state.mentalExam.sensorium.clear}
                      checked={this.state.mentalExam.sensorium.clear}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="clear">
                      Clear
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sensorium.impaired"
                      id="impaired"
                      value={this.state.mentalExam.sensorium.impaired}
                      checked={this.state.mentalExam.sensorium.impaired}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="impaired">
                      Impaired
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sensorium.others"
                      id="sensorium.others"
                      value={this.state.mentalExam.sensorium.others}
                      checked={this.state.mentalExam.sensorium.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="sensorium.others">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="sensorium.others_field"
                      id="sensorium.others_field"
                      value={this.state.mentalExam.sensorium.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.psychomotor ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Psychomotor and Musculoskeletal Activity</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.normal_age"
                      id="normal_age"
                      value={this.state.mentalExam.psychomotor.normal_age}
                      checked={this.state.mentalExam.psychomotor.normal_age}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="normal_age">
                      Normal (age appropriate)
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.hyperactive"
                      id="hyperactive"
                      value={this.state.mentalExam.psychomotor.hyperactive}
                      checked={this.state.mentalExam.psychomotor.hyperactive}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="hyperactive">
                      Hyperactive
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.eps"
                      id="eps"
                      value={this.state.mentalExam.psychomotor.eps}
                      checked={this.state.mentalExam.psychomotor.eps}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="eps">
                      EPS
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.agitated"
                      id="agitated"
                      value={this.state.mentalExam.psychomotor.agitated}
                      checked={this.state.mentalExam.psychomotor.agitated}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="agitated">
                      Agitated
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.retarded"
                      id="psychomotor.retarded"
                      value={this.state.mentalExam.psychomotor.retarded}
                      checked={this.state.mentalExam.psychomotor.retarded}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="psychomotor.retarded">
                      Retarded
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.hyperkinetic"
                      id="hyperkinetic"
                      value={this.state.mentalExam.psychomotor.hyperkinetic}
                      checked={this.state.mentalExam.psychomotor.hyperkinetic}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="hyperkinetic">
                      Hyperkinetic
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.abnormalMovement"
                      id="abnormalMovement"
                      value={this.state.mentalExam.psychomotor.abnormalMovement}
                      checked={
                        this.state.mentalExam.psychomotor.abnormalMovement
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="abnormalMovement">
                      Abnormal Movement
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.tremors"
                      id="tremors"
                      value={this.state.mentalExam.psychomotor.tremors}
                      checked={this.state.mentalExam.psychomotor.tremors}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="tremors">
                      Tremors
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.motorTics"
                      id="motorTics"
                      value={this.state.mentalExam.psychomotor.motorTics}
                      checked={this.state.mentalExam.psychomotor.motorTics}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="motorTics">
                      Motor Tics
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.vocalTics"
                      id="vocalTics"
                      value={this.state.mentalExam.psychomotor.vocalTics}
                      checked={this.state.mentalExam.psychomotor.vocalTics}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="vocalTics">
                      Vocal Tics
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.stereotypies"
                      id="stereotypies"
                      value={this.state.mentalExam.psychomotor.stereotypies}
                      checked={this.state.mentalExam.psychomotor.stereotypies}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="stereotypies">
                      Stereotypies
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="psychomotor.others"
                      id="psychomotor.others"
                      value={this.state.mentalExam.psychomotor.others}
                      checked={this.state.mentalExam.psychomotor.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="psychomotor.others"
                    >
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="psychomotor.others_field"
                      id="psychomotor.others_field"
                      value={this.state.mentalExam.psychomotor.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.mood ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Mood (per client's own words)</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.neutral"
                      id="neutral"
                      value={this.state.mentalExam.mood.neutral}
                      checked={this.state.mentalExam.mood.neutral}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="neutral">
                      Neutral
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.happy"
                      id="happy"
                      value={this.state.mentalExam.mood.happy}
                      checked={this.state.mentalExam.mood.happy}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="happy">
                      Happy
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.fine"
                      id="fine"
                      value={this.state.mentalExam.mood.fine}
                      checked={this.state.mentalExam.mood.fine}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="fine">
                      Fine
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.dontKnow"
                      id="dontKnow"
                      value={this.state.mentalExam.mood.dontKnow}
                      checked={this.state.mentalExam.mood.dontKnow}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="dontKnow">
                      Don't Know
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.depressed"
                      id="depressed"
                      value={this.state.mentalExam.mood.depressed}
                      checked={this.state.mentalExam.mood.depressed}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="depressed">
                      Depressed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.anxious"
                      id="anxious"
                      value={this.state.mentalExam.mood.anxious}
                      checked={this.state.mentalExam.mood.anxious}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="anxious">
                      Anxious
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.irritable"
                      id="irritable"
                      value={this.state.mentalExam.mood.irritable}
                      checked={this.state.mentalExam.mood.irritable}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="irritable">
                      Irritable
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.angry"
                      id="angry"
                      value={this.state.mentalExam.mood.angry}
                      checked={this.state.mentalExam.mood.angry}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="angry">
                      Angry
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.great"
                      id="great"
                      value={this.state.mentalExam.mood.great}
                      checked={this.state.mentalExam.mood.great}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="great">
                      Great
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.ok"
                      id="ok"
                      value={this.state.mentalExam.mood.ok}
                      checked={this.state.mentalExam.mood.ok}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="ok">
                      OK
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.noAnswer"
                      id="noAnswer"
                      value={this.state.mentalExam.mood.noAnswer}
                      checked={this.state.mentalExam.mood.noAnswer}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="noAnswer">
                      No answer
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mood.others"
                      id="mood.others"
                      value={this.state.mentalExam.mood.others}
                      checked={this.state.mentalExam.mood.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="mood.others">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="mood.others_field"
                      id="mood.others_field"
                      value={this.state.mentalExam.mood.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.affect ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Affect</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.euthymic"
                      id="euthymic"
                      value={this.state.mentalExam.affect.euthymic}
                      checked={this.state.mentalExam.affect.euthymic}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="euthymic">
                      Euthymic (Full Range and Reactive)
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.moodCongruent"
                      id="moodCongruent"
                      value={this.state.mentalExam.affect.moodCongruent}
                      checked={this.state.mentalExam.affect.moodCongruent}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="moodCongruent">
                      Mood Congruent
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.moodIncongruent"
                      id="moodIncongruent"
                      value={this.state.mentalExam.affect.moodIncongruent}
                      checked={this.state.mentalExam.affect.moodIncongruent}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="moodIncongruent">
                      Mood Incongruent
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.flat"
                      id="flat"
                      value={this.state.mentalExam.affect.flat}
                      checked={this.state.mentalExam.affect.flat}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="flat">
                      Flat
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.constricted"
                      id="constricted"
                      value={this.state.mentalExam.affect.constricted}
                      checked={this.state.mentalExam.affect.constricted}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="constricted">
                      Constricted
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.blunted"
                      id="blunted"
                      value={this.state.mentalExam.affect.blunted}
                      checked={this.state.mentalExam.affect.blunted}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="blunted">
                      Blunted
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.labile"
                      id="labile"
                      value={this.state.mentalExam.affect.labile}
                      checked={this.state.mentalExam.affect.labile}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="labile">
                      Labile
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affect.others"
                      id="affect.others"
                      value={this.state.mentalExam.affect.others}
                      checked={this.state.mentalExam.affect.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="affect.others">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="affect.others_field"
                      id="affect.others_field"
                      value={this.state.mentalExam.affect.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.speechLanguage ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Speech/Language</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.normal_rate"
                      id="normal_rate"
                      value={this.state.mentalExam.speechLanguage.normal_rate}
                      checked={this.state.mentalExam.speechLanguage.normal_rate}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="normal_rate">
                      Normal rate, rhythm, and tone
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.slow"
                      id="slow"
                      value={this.state.mentalExam.speechLanguage.slow}
                      checked={this.state.mentalExam.speechLanguage.slow}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="slow">
                      Slow
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.soft"
                      id="soft"
                      value={this.state.mentalExam.speechLanguage.soft}
                      checked={this.state.mentalExam.speechLanguage.soft}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="soft">
                      Soft
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.loud"
                      id="loud"
                      value={this.state.mentalExam.speechLanguage.loud}
                      checked={this.state.mentalExam.speechLanguage.loud}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="loud">
                      Loud
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.hyperverbal"
                      id="hyperverbal"
                      value={this.state.mentalExam.speechLanguage.hyperverbal}
                      checked={this.state.mentalExam.speechLanguage.hyperverbal}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="hyperverbal">
                      Hyperverbal
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.rapid"
                      id="rapid"
                      value={this.state.mentalExam.speechLanguage.rapid}
                      checked={this.state.mentalExam.speechLanguage.rapid}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="rapid">
                      rapid
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.pressured"
                      id="pressured"
                      value={this.state.mentalExam.speechLanguage.pressured}
                      checked={this.state.mentalExam.speechLanguage.pressured}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="pressured">
                      Pressured
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.delayed"
                      id="delayed"
                      value={this.state.mentalExam.speechLanguage.delayed}
                      checked={this.state.mentalExam.speechLanguage.delayed}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="delayed">
                      Delayed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.selectivelyMute"
                      id="selectivelyMute"
                      value={
                        this.state.mentalExam.speechLanguage.selectivelyMute
                      }
                      checked={
                        this.state.mentalExam.speechLanguage.selectivelyMute
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="selectivelyMute">
                      Selectively Mute
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.stuttering"
                      id="stuttering"
                      value={this.state.mentalExam.speechLanguage.stuttering}
                      checked={this.state.mentalExam.speechLanguage.stuttering}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="stuttering">
                      Stuttering
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="speechLanguage.others"
                      id="speechLanguage.others"
                      value={this.state.mentalExam.speechLanguage.others}
                      checked={this.state.mentalExam.speechLanguage.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="speechLanguage.others"
                    >
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="speechLanguage.others_field"
                      id="speechLanguage.others_field"
                      value={this.state.mentalExam.speechLanguage.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.thoughtProcesses ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Thought Processes</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.linearGoalDirected"
                      id="linearGoalDirected"
                      value={
                        this.state.mentalExam.thoughtProcesses
                          .linearGoalDirected
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses
                          .linearGoalDirected
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="linearGoalDirected"
                    >
                      Linear Goal Directed
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.circumstantial"
                      id="circumstantial"
                      value={
                        this.state.mentalExam.thoughtProcesses.circumstantial
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses.circumstantial
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="circumstantial">
                      Circumstantial
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.tangential"
                      id="tangential"
                      value={this.state.mentalExam.thoughtProcesses.tangential}
                      checked={
                        this.state.mentalExam.thoughtProcesses.tangential
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="tangential">
                      Tangential
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.retarded"
                      id="retarded"
                      value={this.state.mentalExam.thoughtProcesses.retarded}
                      checked={this.state.mentalExam.thoughtProcesses.retarded}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="retarded">
                      Retarded
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.looseAssociations"
                      id="looseAssociations"
                      value={
                        this.state.mentalExam.thoughtProcesses.looseAssociations
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses.looseAssociations
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="looseAssociations">
                      Loose Associations
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.flightOfIdeas"
                      id="flightOfIdeas"
                      value={
                        this.state.mentalExam.thoughtProcesses.flightOfIdeas
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses.flightOfIdeas
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="flightOfIdeas">
                      Flight of Ideas
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.blocking"
                      id="blocking"
                      value={this.state.mentalExam.thoughtProcesses.blocking}
                      checked={this.state.mentalExam.thoughtProcesses.blocking}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="blocking">
                      Blocking
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.perseverations"
                      id="perseverations"
                      value={
                        this.state.mentalExam.thoughtProcesses.perseverations
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses.perseverations
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="perseverations">
                      Perseverations
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.disorganized"
                      id="disorganized"
                      value={
                        this.state.mentalExam.thoughtProcesses.disorganized
                      }
                      checked={
                        this.state.mentalExam.thoughtProcesses.disorganized
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="disorganized">
                      Disorganized
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="thoughtProcesses.others"
                      id="thoughtProcesses.others"
                      value={this.state.mentalExam.thoughtProcesses.others}
                      checked={this.state.mentalExam.thoughtProcesses.others}
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="thoughtProcesses.others"
                    >
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      name="thoughtProcesses.others_field"
                      id="thoughtProcesses.others_field"
                      value={
                        this.state.mentalExam.thoughtProcesses.others_field
                      }
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.thoughtContent ? "show" : "d-none"
              }`}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label bold premium-color">
                      <b>Thought Content</b>
                    </label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.realityOriented"
                          id="realityOriented"
                          value={
                            this.state.mentalExam.thoughtContent.realityOriented
                          }
                          checked={
                            this.state.mentalExam.thoughtContent.realityOriented
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="realityOriented"
                        >
                          Reality Oriented
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.urgesToSelfInjure"
                          id="urgesToSelfInjure"
                          value={
                            this.state.mentalExam.thoughtContent
                              .urgesToSelfInjure
                          }
                          checked={
                            this.state.mentalExam.thoughtContent
                              .urgesToSelfInjure
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="urgesToSelfInjure"
                        >
                          Urges to Self-injure
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.hopeless"
                          id="hopeless"
                          value={this.state.mentalExam.thoughtContent.hopeless}
                          checked={
                            this.state.mentalExam.thoughtContent.hopeless
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="hopeless">
                          Hopeless/helpless
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.poorBodyImage"
                          id="poorBodyImage"
                          value={
                            this.state.mentalExam.thoughtContent.poorBodyImage
                          }
                          checked={
                            this.state.mentalExam.thoughtContent.poorBodyImage
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="poorBodyImage">
                          Poor body image
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.delusions"
                          id="delusions"
                          value={this.state.mentalExam.thoughtContent.delusions}
                          checked={
                            this.state.mentalExam.thoughtContent.delusions
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="delusions">
                          Delusions (Paranoid, Grandiose, Referential, Somatic,
                          Jealousy, Erotic)
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.impoverished"
                          id="impoverished"
                          value={
                            this.state.mentalExam.thoughtContent.impoverished
                          }
                          checked={
                            this.state.mentalExam.thoughtContent.impoverished
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="impoverished">
                          Impoverished
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.obsessional"
                          id="obsessional"
                          value={
                            this.state.mentalExam.thoughtContent.obsessional
                          }
                          checked={
                            this.state.mentalExam.thoughtContent.obsessional
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="obsessional">
                          Obsessional
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.povertyOfContent"
                          id="povertyOfContent"
                          value={
                            this.state.mentalExam.thoughtContent
                              .povertyOfContent
                          }
                          checked={
                            this.state.mentalExam.thoughtContent
                              .povertyOfContent
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="povertyOfContent"
                        >
                          Poverty of Content
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thoughtContent.others"
                          id="thoughtContent.others"
                          value={this.state.mentalExam.thoughtContent.others}
                          checked={this.state.mentalExam.thoughtContent.others}
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="thoughtContent.others"
                        >
                          Other
                        </label>
                        <input
                          className="form-check-input w-50"
                          type="text"
                          name="thoughtContent.others_field"
                          id="thoughtContent.others_field"
                          value={
                            this.state.mentalExam.thoughtContent.others_field
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-check">
                    <label className="form-check-label">
                      <b>Describe if needed</b>
                    </label>
                    <br />
                    <textarea
                      className="w-75"
                      name="thoughtContent.description"
                      id="thoughtContent.description"
                      value={this.state.mentalExam.thoughtContent.description}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{ resize: "none" }}
                      rows={2}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.perceptions ? "show" : "d-none"
              }`}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="control-label bold premium-color">
                      <b>Perceptions</b>
                    </label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="perceptions.noPerceptionIssues"
                          id="noPerceptionIssues"
                          value={
                            this.state.mentalExam.perceptions.noPerceptionIssues
                          }
                          checked={
                            this.state.mentalExam.perceptions.noPerceptionIssues
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="noPerceptionIssues"
                        >
                          No Perception Issues
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="perceptions.auditoryHallucinations"
                          id="auditoryHallucinations"
                          value={
                            this.state.mentalExam.perceptions
                              .auditoryHallucinations
                          }
                          checked={
                            this.state.mentalExam.perceptions
                              .auditoryHallucinations
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auditoryHallucinations"
                        >
                          Auditory Hallucinations
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="perceptions.visualHallucinations"
                          id="visualHallucinations"
                          value={
                            this.state.mentalExam.perceptions
                              .visualHallucinations
                          }
                          checked={
                            this.state.mentalExam.perceptions
                              .visualHallucinations
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="visualHallucinations"
                        >
                          Visual Hallucinations
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="perceptions.illusions"
                          id="illusions"
                          value={this.state.mentalExam.perceptions.illusions}
                          checked={this.state.mentalExam.perceptions.illusions}
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label className="form-check-label" htmlFor="illusions">
                          Illusions
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="perceptions.others"
                          id="perceptions.others"
                          value={this.state.mentalExam.perceptions.others}
                          checked={this.state.mentalExam.perceptions.others}
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="perceptions.others"
                        >
                          Other
                        </label>
                        <input
                          className="form-check-input w-50"
                          type="text"
                          name="perceptions.others_field"
                          id="perceptions.others_field"
                          value={this.state.mentalExam.perceptions.others_field}
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-check">
                    <label className="form-check-label">
                      <b>Describe if needed</b>
                    </label>
                    <br />
                    <textarea
                      className="w-75"
                      name="perceptions.description"
                      id="perceptions.description"
                      value={this.state.mentalExam.perceptions.description}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                      style={{ resize: "none" }}
                      rows={2}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.sucidialThoughts ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Suicidal Thoughts</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sucidialThoughts.sucidialThoughtNo"
                      id="sucidialThoughtNo"
                      value={
                        this.state.mentalExam.sucidialThoughts.sucidialThoughtNo
                      }
                      checked={
                        this.state.mentalExam.sucidialThoughts.sucidialThoughtNo
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label className="form-check-label" htmlFor="sucidialThoughtNo">
                      No
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sucidialThoughts.sucidialThoughtYes"
                      id="sucidialThoughtYes"
                      value={
                        this.state.mentalExam.sucidialThoughts
                          .sucidialThoughtYes
                      }
                      checked={
                        this.state.mentalExam.sucidialThoughts
                          .sucidialThoughtYes
                      }
                      onChange={(event) => this.handleCheckboxChange(event)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="sucidialThoughtYes"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.sucidialThoughts ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <table className="table">
                  <thead>
                    <tr className="text-center table-secondary">
                      <th scope="col">Suicidal Thoughts</th>
                      <th scope="col">Frequency</th>
                      <th scope="col">Plan</th>
                      <th scope="col">Intent</th>
                      <th scope="col">Active or Passive</th>
                      <th scope="col">Chronic or Acute</th>
                      <th scope="col">Means</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.sucidialThoughtInput"
                          id="sucidialThoughts.sucidialThoughtInput"
                          value={
                            this.state.mentalExam.sucidialThoughts
                              .sucidialThoughtInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.frequencyInput"
                          id="sucidialThoughts.frequencyInput"
                          value={
                            this.state.mentalExam.sucidialThoughts
                              .frequencyInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.planInput"
                          id="sucidialThoughts.planInput"
                          value={
                            this.state.mentalExam.sucidialThoughts.planInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.intentInput"
                          id="sucidialThoughts.intentInput"
                          value={
                            this.state.mentalExam.sucidialThoughts.intentInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.activePassiveInput"
                          id="sucidialThoughts.activePassiveInput"
                          value={
                            this.state.mentalExam.sucidialThoughts
                              .activePassiveInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.chronicOrAcuteInput"
                          id="sucidialThoughts.chronicOrAcuteInput"
                          value={
                            this.state.mentalExam.sucidialThoughts
                              .chronicOrAcuteInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="w-75"
                          type="text"
                          name="sucidialThoughts.meansInput"
                          id="sucidialThoughts.meansInput"
                          value={
                            this.state.mentalExam.sucidialThoughts.meansInput
                          }
                          onChange={(event) => this.handleInputChange(event)}
                          onBlur={(event) => this.handleInputChange(event)}
                          onKeyUp={(event) => this.handleInputChange(event)}
                          style={{
                            marginLeft: "15px",
                            border: "none",
                            borderBottom: "1px solid",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.homicidalThoughts ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label className="control-label bold premium-color">
                      <b>Homicidal Thoughts</b>
                    </label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="homicidalThoughts.homicidalThoughtsNo"
                          id="homicidalThoughtsNo"
                          value={
                            this.state.mentalExam.homicidalThoughts
                              .homicidalThoughtsNo
                          }
                          checked={
                            this.state.mentalExam.homicidalThoughts
                              .homicidalThoughtsNo
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="homicidalThoughtsNo"
                        >
                          No
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="homicidalThoughts.homicidalThoughtsYes"
                          id="homicidalThoughtsYes"
                          value={
                            this.state.mentalExam.homicidalThoughts
                              .homicidalThoughtsYes
                          }
                          checked={
                            this.state.mentalExam.homicidalThoughts
                              .homicidalThoughtsYes
                          }
                          onChange={(event) => this.handleCheckboxChange(event)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="homicidalThoughtsYes"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-check">
                      <label className="form-check-label">
                        <b>Describe if needed</b>
                      </label>
                      <br />
                      <textarea
                        className="w-50 text-center"
                        name="homicidalThoughts.description"
                        id="homicidalThoughts.description"
                        value={
                          this.state.mentalExam.homicidalThoughts.description
                        }
                        onChange={(event) => this.handleInputChange(event)}
                        onBlur={(event) => this.handleInputChange(event)}
                        onKeyUp={(event) => this.handleInputChange(event)}
                        style={{ resize: "none" }}
                        rows={1}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 ${
                this.state.component.capacityOfAdls ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <div>
                  <label className="control-label bold premium-color">
                    <b>Capacity of ADLs</b>
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="capacityOfAdls.independent"
                    id="independent"
                    value={this.state.mentalExam.capacityOfAdls.independent}
                    checked={this.state.mentalExam.capacityOfAdls.independent}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" htmlFor="independent">
                    Independent
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="capacityOfAdls.someAssistance"
                    id="someAssistance"
                    value={this.state.mentalExam.capacityOfAdls.someAssistance}
                    checked={
                      this.state.mentalExam.capacityOfAdls.someAssistance
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" htmlFor="someAssistance">
                    Some assistance and/or direction required
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="capacityOfAdls.dependent"
                    id="dependent"
                    value={this.state.mentalExam.capacityOfAdls.dependent}
                    checked={this.state.mentalExam.capacityOfAdls.dependent}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" htmlFor="dependent">
                    Dependent - requires total care
                  </label>
                </div>
              </div>
            </div>

            <div
              className={`col-md-6 ${
                this.state.component.orientations ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Orientation </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="person"
                      name="orientations.person"
                      checked={this.state.mentalExam.orientations.person}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="person">
                      Person
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="place"
                      name="orientations.place"
                      checked={this.state.mentalExam.orientations.place}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="place">
                      Place
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="date"
                      name="orientations.date"
                      checked={this.state.mentalExam.orientations.date}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="date">
                      Date
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="time"
                      name="orientations.time"
                      checked={this.state.mentalExam.orientations.time}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="time">
                      Time
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="situation"
                      name="orientations.situation"
                      checked={this.state.mentalExam.orientations.situation}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="situation">
                      Situation
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.concentrationAndAttention
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Concentration and Attention </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="concentrationAndAttention.intact"
                      name="concentrationAndAttention.intact"
                      checked={
                        this.state.mentalExam.concentrationAndAttention.intact
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="concentrationAndAttention.intact"
                    >
                      Intact
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="impairedConcentration"
                      name="concentrationAndAttention.impairedConcentration"
                      checked={
                        this.state.mentalExam.concentrationAndAttention
                          .impairedConcentration
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="impairedConcentration"
                    >
                      Impaired Concentration
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="impairedattention"
                      name="concentrationAndAttention.impairedAttentation"
                      checked={
                        this.state.mentalExam.concentrationAndAttention
                          .impairedAttentation
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="impairedattention">
                      Impaired Attention
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.worldSpell ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>WORLD spelled backwards</b>
                </label>
                <div className="form-group">
                  <table className="table">
                    <thead>
                      <tr className="text-center table-secondary">
                        <th scope="col">D</th>
                        <th scope="col">L</th>
                        <th scope="col">R</th>
                        <th scope="col">O</th>
                        <th scope="col">W</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="worldSpell.DInput"
                            id="worldSpell.DInput"
                            value={this.state.mentalExam.worldSpell.DInput}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="worldSpell.LInput"
                            id="worldSpell.LInput"
                            value={this.state.mentalExam.worldSpell.LInput}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="worldSpell.RInput"
                            id="worldSpell.RInput"
                            value={this.state.mentalExam.worldSpell.RInput}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="worldSpell.OInput"
                            id="worldSpell.OInput"
                            value={this.state.mentalExam.worldSpell.OInput}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="worldSpell.WInput"
                            id="worldSpell.WInput"
                            value={this.state.mentalExam.worldSpell.WInput}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.memoryImmediate ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Memory Immediate: Digit Span Repetition (2-6-8-9-3-1-5)</b>
                </label>
                <div className="form-group">
                  <table className="table">
                    <thead>
                      <tr className="text-center table-secondary">
                        <th scope="col">2</th>
                        <th scope="col">6</th>
                        <th scope="col">8</th>
                        <th scope="col">9</th>
                        <th scope="col">3</th>
                        <th scope="col">1</th>
                        <th scope="col">5</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.two"
                            id="memoryImmediate.two"
                            value={this.state.mentalExam.memoryImmediate.two}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.six"
                            id="memoryImmediate.six"
                            value={this.state.mentalExam.memoryImmediate.six}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.eight"
                            id="memoryImmediate.eight"
                            value={this.state.mentalExam.memoryImmediate.eight}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.nine"
                            id="memoryImmediate.nine"
                            value={this.state.mentalExam.memoryImmediate.nine}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.three"
                            id="memoryImmediate.three"
                            value={this.state.mentalExam.memoryImmediate.three}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.one"
                            id="memoryImmediate.one"
                            value={this.state.mentalExam.memoryImmediate.one}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>

                        <td>
                          <input
                            className="w-75"
                            type="text"
                            style={{
                              marginLeft: "15px",
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            name="memoryImmediate.five"
                            id="memoryImmediate.five"
                            value={this.state.mentalExam.memoryImmediate.five}
                            onChange={(event) => this.handleInputChange(event)}
                            onBlur={(event) => this.handleInputChange(event)}
                            onKeyUp={(event) => this.handleInputChange(event)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.insightGood ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Insight: Through conversation with the provider insight was assessed to be</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="insightGood"
                      name="insightGood.good"
                      checked={this.state.mentalExam.insightGood.good}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="insightGood">
                      Good
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="insighLimited"
                      name="insightGood.limited"
                      checked={this.state.mentalExam.insightGood.limited}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="insighLimited">
                      Limited
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="insighPoor"
                      name="insightGood.poor"
                      checked={this.state.mentalExam.insightGood.poor}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="insighPoor">
                      Poor
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="insighOther"
                      name="insightGood.others"
                      checked={this.state.mentalExam.insightGood.others}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="insighOther">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      name="insightGood.others_field"
                      id="insightGood.others_field"
                      value={this.state.mentalExam.insightGood.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.judgment ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Judgment: What would you do if you smelled smoke in a crowded movie theater?</b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="judgmentGood"
                      name="judgment.good"
                      checked={this.state.mentalExam.judgment.good}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="judgmentGood">
                      Good
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="judgmentLimited"
                      name="judgment.limited"
                      checked={this.state.mentalExam.judgment.limited}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="judgmentLimited">
                      Limited
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="judgmentPoor"
                      name="judgment.poor"
                      checked={this.state.mentalExam.judgment.poor}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="judgmentPoor">
                      Poor
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="judgmentOther"
                      name="judgment.others"
                      checked={this.state.mentalExam.judgment.others}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="judgmentOther">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      name="judgment.others_field"
                      id="judgment.others_field"
                      value={this.state.mentalExam.judgment.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-6 ${
                this.state.component.intelligence ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Intelligence: Vocabulary, general fund of knowledge, educational level </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="intelligenceAboveAverage"
                      name="intelligence.aboveAverage"
                      checked={this.state.mentalExam.intelligence.aboveAverage}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="intelligenceAboveAverage"
                    >
                      Above Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="intelligenceAverage"
                      name="intelligence.average"
                      checked={this.state.mentalExam.intelligence.average}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="intelligenceAverage"
                    >
                      Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="intelligenceBelowAverage"
                      name="intelligence.belowAverage"
                      checked={this.state.mentalExam.intelligence.belowAverage}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="intelligenceBelowAverage"
                    >
                      Below Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="intelligenceOther"
                      name="intelligence.others"
                      checked={this.state.mentalExam.intelligence.others}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="intelligenceOther">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      name="intelligence.others_field"
                      id="intelligence.others_field"
                      value={this.state.mentalExam.intelligence.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.memoryRecent ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Memory: Recent </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="lamp"
                      name="memoryRecent.lamp"
                      checked={this.state.mentalExam.memoryRecent.lamp}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="lamp">
                      Lamp
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="umberella"
                      name="memoryRecent.umberella"
                      checked={this.state.mentalExam.memoryRecent.umberella}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="umberella">
                      Umberella
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="telephone"
                      name="memoryRecent.telephone"
                      checked={this.state.mentalExam.memoryRecent.telephone}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="telephone">
                      Telephone
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.memoryRemote ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Memory Remote: Name as many presidents as you can starting with current one and going backwards:</b>
                </label>
                <div>
                  <div className="form-check form-check-inline w-100">
                    <input
                      className="form-check-input w-100"
                      type="text"
                      style={{
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      name="memoryRemote.remoteInput"
                      value={this.state.mentalExam.memoryRemote.remoteInput}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.patientAssessment ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Assessment of Client's Memory </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="patientAssessment.intact"
                      name="patientAssessment.intact"
                      checked={this.state.mentalExam.patientAssessment.intact}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="patientAssessment.intact"
                    >
                      Intact
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="impairedImmediate"
                      name="patientAssessment.impairedImmediate"
                      checked={
                        this.state.mentalExam.patientAssessment
                          .impairedImmediate
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="impairedImmediate">
                      Impaired Immediate
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="impairedRecent"
                      name="patientAssessment.impairedRecent"
                      checked={
                        this.state.mentalExam.patientAssessment.impairedRecent
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="impairedRecent">
                      Impaired Recent
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="impairedRemote"
                      name="patientAssessment.impairedRemote"
                      checked={
                        this.state.mentalExam.patientAssessment.impairedRemote
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="impairedRemote">
                      Impaired Remote
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.concentration ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Concentration and Attention </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="concentrationDevelopmentallyAppropriate"
                      name="concentration.developmentallyAppropriate"
                      checked={
                        this.state.mentalExam.concentration
                          .developmentallyAppropriate
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="concentrationDevelopmentallyAppropriate"
                    >
                      Developmentally Appropriate Focus and Attention
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="concentrationMildDistractibility"
                      name="concentration.mildDistractibility"
                      checked={
                        this.state.mentalExam.concentration.mildDistractibility
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="concentrationMildDistractibility"
                    >
                      Mild Distractibility and Limited Focus
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="concentrationSeveresDistractibility"
                      name="concentration.severeDistractibility"
                      checked={
                        this.state.mentalExam.concentration
                          .severeDistractibility
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="concentrationSeveresDistractibility"
                    >
                      Severe Distractibility and Poor Focus
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-6 ${
                this.state.component.memory ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Memory </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="memoryDevelopmentallyAppropriate"
                      name="memory.developmentallyAppropriate"
                      checked={
                        this.state.mentalExam.memory.developmentallyAppropriate
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="memoryDevelopmentallyAppropriate"
                    >
                      Developmentally Appropriate Memory
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="memoryMildImpairment"
                      name="memory.mildImpairment"
                      checked={this.state.mentalExam.memory.mildImpairment}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="memoryMildImpairment"
                    >
                      Mild Impairment in Memory
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="memoryModerateImpairment"
                      name="memory.moderateImpairment"
                      checked={this.state.mentalExam.memory.moderateImpairment}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="memoryModerateImpairment"
                    >
                      Moderate Impairment in Memory
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="memorySevereImpairment"
                      name="memory.severeImpairment"
                      checked={this.state.mentalExam.memory.severeImpairment}
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="memorySevereImpairment"
                    >
                      Severe Impairment in Memory
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-md-6 ${
                this.state.component.fundOfKnowledge ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold premium-color">
                  <b>Fund of Knowledge </b>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fundDevelopmentallyAppropriate"
                      name="fundOfKnowledge.developmentallyAppropriate"
                      checked={
                        this.state.mentalExam.fundOfKnowledge
                          .developmentallyAppropriate
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="fundDevelopmentallyAppropriate"
                    >
                      Developmentally Appropriate
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fundAverage"
                      name="fundOfKnowledge.average"
                      checked={this.state.mentalExam.fundOfKnowledge.average}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="fundAverage">
                      Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fundAboveAverage"
                      name="fundOfKnowledge.aboveAverage"
                      checked={
                        this.state.mentalExam.fundOfKnowledge.aboveAverage
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="fundAboveAverage"
                    >
                      Above Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fundBelowAverage"
                      name="fundOfKnowledge.belowAverage"
                      checked={
                        this.state.mentalExam.fundOfKnowledge.belowAverage
                      }
                      onChange={this.handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="fundBelowAverage"
                    >
                      Below Average
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fundOther"
                      name="fundOfKnowledge.others"
                      checked={this.state.mentalExam.fundOfKnowledge.others}
                      onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="fundOther">
                      Other
                    </label>
                    <input
                      className="form-check-input w-50"
                      type="text"
                      style={{
                        marginLeft: "15px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      name="fundOfKnowledge.others_field"
                      id="fundOfKnowledge.others_field"
                      value={this.state.mentalExam.fundOfKnowledge.others_field}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class MentalStatusExam extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */

  static get builderInfo() {
    return {
      title: "Mental Status Exam",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: MentalStatusExam.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "mentalStatusExamComp",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <MentalStatusExamComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
