const resetObject = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => resetObject(item));
  } else if (typeof obj === "object" && obj !== null) {
    const result = {};
    for (const key in obj) {
      result[key] = resetObject(obj[key]);
    }
    return result;
  } else if (typeof obj === "boolean") {
    return false;
  } else if (typeof obj === "string") {
    return "";
  } else if (typeof obj === "number") {
    return 0;
  } else {
    return obj;
  }
};

const modifyPreviousState = (objA, objB) => {
  const result = { ...objA };

  for (const key in objB) {
    if (!Object.keys(objA).includes(key)) {
      result[key] = resetObject(objB[key]);
    }
  }

  return result;
};

const isAlphanumeric = (char) => {
  return /^[a-zA-Z0-9]$/.test(char);
};

const getValue = (value) => {
  if (Array.isArray(value)) {
    if (value.length < 3) return "";

    return value[2].toString();
  }

  return value.toString();
};

const skipScript = (value) => {
  let val = value.toString();
  return val.slice(0, val.indexOf("{"));
};

const setValue = (changedValue) => {
  let value = changedValue.value;

  if (Array.isArray(value)) {
    value[2] = skipScript(value[2]);
    return value;
  } else {
    return skipScript(value);
  }
};

const updateIndex = (currentIndex, previousValue, actualValue, key) => {
  if (
    ["script"].includes(key) &&
    previousValue.length > 1 &&
    previousValue.slice(-1) == " "
  )
    return false;

  if (["script"].includes(key) && previousValue === actualValue) return false;

  if (
    currentIndex === 0 ||
    [previousValue, actualValue].includes("") ||
    ["script", "boolean", "Delete", "speech"].includes(key)
  )
    return true;

  switch (true) {
    case isAlphanumeric(actualValue.slice(-1)) &&
      actualValue.slice(-2, -1) === "\n":
      return true;
    case actualValue.length > 1 &&
      [" ", "?", ".", "!", ";", ","].includes(actualValue.slice(-1)) &&
      actualValue.slice(-2, -1) === actualValue.slice(-1):
      return false;
    case !isAlphanumeric(actualValue.slice(-1)):
      return true;
    default:
      return false;
  }
};


export const removeTableComponentFromData = (data) => {
  let obj = { ...data };
  for (var key in obj) {
    if (key.includes("tableCustomComp")) {
      delete obj[key];
    }
  }
  return obj;
};

export const setSessionStorage = (data, index, keys) => {
  sessionStorage.setItem("formHistory", JSON.stringify(data));
  sessionStorage.setItem("currentIndex", index);
  sessionStorage.setItem("componentKeys", JSON.stringify(keys));  
};

export const cleanupSessionStorage = () => {
  sessionStorage.clear();
};

export const saveUndoRedoHistory = (data, changedValue, key) => {
  var changedValueKey = changedValue.key || changedValue.component.key;
  var actualValue = getValue(changedValue?.value || "");
  var currentIndex = Number(sessionStorage.getItem("currentIndex"), 10) || 0;
  var history = JSON.parse(sessionStorage.getItem("formHistory"));
  var componentKeys = JSON.parse(sessionStorage.getItem("componentKeys"));
  var previousValue = getValue(
    history[currentIndex][changedValueKey] || ""
  );

  if (history?.length > 0) {
    if (history?.length === 1)
      history = [removeTableComponentFromData(history[0])];
    if (Object.keys(history[currentIndex]).length != Object.keys(data).length) {
      history[currentIndex] = modifyPreviousState(
        history[currentIndex],
        data
      );
    }

    let canUpdateIndex = updateIndex(
      currentIndex,
      previousValue,
      actualValue,
      key
    );

    if (actualValue.includes("\n") && !canUpdateIndex) {
      if (
        actualValue.split("\n").length - previousValue.split("\n").length > 0 &&
        actualValue.slice(-1) != "\n"
      ) {
        canUpdateIndex = true;
      }
    }

    if (actualValue.includes("{")) {
      history[currentIndex][changedValueKey] = setValue(changedValue);

      if (
        (actualValue.slice(-1) != "{" && !actualValue.includes("}")) ||
        (actualValue.includes("}") &&
          actualValue.includes("{") &&
          actualValue.slice(-1) == " ")
      )
        canUpdateIndex = false;
    }

    if (canUpdateIndex) {
      currentIndex += 1;
    }
    history = [...history.slice(0, currentIndex), data];
    componentKeys = [...componentKeys.slice(0, currentIndex), changedValueKey];
  } else {
    history = [removeTableComponentFromData(data)];
    componentKeys = [changedValueKey];
  }
  setSessionStorage(history, currentIndex, componentKeys);
};
