import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Signature.settingsForm";

const SignatureComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      data: {},
    };
  }

  getValue() {
    return this.state.data;
  }

  setValue(value) {
    if (value !== null) {
      if(this.props.component.id == value.id){
        this.setState({
          data: value,
        });
        }
      
    }
  }

  render() {
    $(".component-edit-container p.lead").html("Signature Widget");
    console.log("SignatureComp", this)
    var signatureType =
      this.state.component.signatureType == "Other"
        ? this.state.component.signatureTypeOther
        : this.state.component.signatureType;
    var currentID = this.state.component.id;
    var dataSignature =
      this.state.component.signatureType + "_signature_" + currentID;
    return (
      <div
        id={this.state.component.key}
        className="signature-widget"
        data-signature-id={currentID}
      >
        {/* <img className="img-fluid" src={SignatureImage} /> */}
        <div style={{ padding: "10px 15px", border: "2px solid" }}>
          <div className="row">
            <div className="col-md-4">
              <input
                type="text"
                name={"text[entry_signature_name_" + currentID + "]"}
                className="line-input w-100 signature-input user-signature"
                value={this.state.data?.name || ""}
                readOnly="readonly"
                data-sign-name={dataSignature}
                data-signer-label={dataSignature}
                data-signer-type={this.state.component.signatureType}
                data-input-name={"entry_signature_name_" + currentID}
              />
              <label className="mb-0 text-center w-100">
                <b>{signatureType} Printed Name</b>
              </label>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name={"text[entry_signature_signature_" + currentID + "]"}
                className="line-input w-100 signature-input user-signature"
                value={this.state.data?.signature || ""}
                readOnly="readonly"
                data-signer-label={dataSignature}
                data-signer-type={this.state.component.signatureType}
                data-input-name={"entry_signature_signature_" + currentID}
              />
              <label className="mb-0 text-center w-100">
                <b>{signatureType} Signature</b>
              </label>
              <input
                type="hidden"
                name={"file[entry_signature_" + currentID + "]"}
                value={this.state.data?.file || ""}
                data-signature={dataSignature}
                data-signed-status="false"
                data-input-name={"entry_signature_" + currentID}
              />
            </div>
            <div className="col-md-3">
              <div style={{ overflow: "hidden" }}>
                <input
                  type="date"
                  name={"date[entry_signature_" + currentID + "]"}
                  className="line-input w-100"
                  value={this.state.data?.date || ""}
                  readOnly="readonly"
                  data-sign-date={dataSignature}
                  data-input-name={"entry_signature_" + currentID}
                />
                <label className="mb-0 text-center w-100">
                  <b>Date</b>
                </label>
              </div>
            </div>
            <div className="col-md-2">
              <div style={{ overflow: "hidden" }}>
                <input
                  type="time"
                  name={"time[entry_signature_" + currentID + "]"}
                  className="line-input w-100"
                  value={this.state.data?.time || ""}
                  readOnly="readonly"
                  data-sign-time={dataSignature}
                  data-input-name={"entry_signature_" + currentID}
                />
                <label className="mb-0 text-center w-100">
                  <b>Time</b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class Signature extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Signature",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Signature.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "signatureComp",
      signatureType: "Provider"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <SignatureComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}