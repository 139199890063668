import consumer from "./consumer"

consumer.subscriptions.create("PendingOrderChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the channel!")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(message) {
    var patient_guid = message.patient_guid;
    var sspor_guid = message.sspor_guid;
    console.log("PendingOrderChannel message Arrived", message);
    var url = window.location.href;
    if (url.includes("/notes") && url.includes("?sspor_guid=")){
      var notes_url_without_sspor = url.replace(`sspor_guid=${sspor_guid}`, "")
      window.location = notes_url_without_sspor;
    }
    else{
      $(`.ssPrescriptionModal-${sspor_guid}`).remove();
      $(".modal-backdrop").remove();
      $(`.ss-prescription-close-${patient_guid}`).click();
      $("#prescription_pending_order_" + patient_guid).load("/orders/prescriptions/e_prescribe/" + patient_guid + "/detail");
      $("#prescription_pending_order_iframe_" + patient_guid).load("/orders/prescriptions/e_prescribe_iframe/" + patient_guid + "/detail");
      $("#prescription_refill_due_completed_" + patient_guid).load("/dashboard/prescription_refills_due/completed/" + patient_guid);
      $("#prescription-refills-due").html("");
      if ($("[data-card='prescription-refills-due']").length > 0){
        $("[data-card='prescription-refills-due']")[0].click();
      }
    }
  }
});
