import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./VitalSigns.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';
import VitalSignsImage from 'images/widget/VitalSigns.png'
import PreviousImage from 'images/widget/vital_history_icon.svg'

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const VitalSignsComp = class extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      vitalSigns: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.vitalSigns);
  }

  getValue() {
    return this.state.vitalSigns;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        vitalSigns: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }

  openEditModal = (data) => {
    if(data.item.can_edit){
       $.post('/form_builders/widgets/new_edit_sb_attr', { 
        attr_type: 'vitalsigns',
        patient_guid: data.item.patient_guid,
        id: data.item.widgetable_id,
        patient_form_guid: data.item.patient_form_guid,
        patient_form_entry_guid: data.item.guid
      }); 
    }  
  }

  openCreateModal = () =>{
    $.post('/form_builders/widgets/new_edit_sb_attr', {
      attr_type: 'vitalsigns',
      patient_guid: $('#patient_guid').val(), patient_form_guid: $('#patient_note_guid').val() });
  }

  showPreviousVitalsModal = () => {
    $.ajax({
      type: 'GET',
      url: '/notes/summary/get_previous_vitals',
      data: {
        patient_guid: $('#patient_guid').val(),
      },
      success: function(data){
      },
      error: function(data){
        swal("Error","Something Went Wrong");
      }
    });

  }

  render() {
    $(".component-edit-container p.lead").html("Vital Signs Widget");
    return (
      <div id={this.state.component.key} className="vital-signs-widget">
        <h3>Vital Signs</h3>
        <div className="pointer float-right vital-history" onClick={() => this.showPreviousVitalsModal()} >
          <img className="mb-1" width="20" height="20" src={PreviousImage} />
          <span> Previous</span>
        </div>
        <table className="widget-table-border w-100">
          <thead className="sb-table-header">
            <tr className="text-center">
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Updated At</div>          
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Blood Pressure Systolic (mmHg)</div>          
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Blood Pressure Diastolic (mmHg)</div>
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Pulse/Heart Rate (beats/min)</div>
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Temp (&#176;F)</div>
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Resp Rate (breaths/min)</div>
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">O<sub>2</sub>Sat (%)</div>
                </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Height (in)</div>
              </div>
              </th>
              <th>
                <div className="th-font-size">
                  <div className="mb-3">Weight (lb)</div>
                </div>
              </th>                
            </tr>
          </thead>
           <tbody className="sb-table-body-vital">
               {this.state.vitalSigns  && this.state.vitalSigns.widget_data.length > 0 && this.state.vitalSigns.widget_data.map(item => {
                return (
                  <tr key={item.guid} data-id={item.guid} data-entry-guid={item.guid} onClick={() => this.openEditModal({item})} >
                  <td style={{width: '12em', padding: '0'}}>
                  <input id="updatedAtVital" className="sb-field form-control" value={item.updated_at} readOnly={true} /></td>
                  <td><input id="sysVital" name="sbp_min" className="sb-field form-control" value={item.sbp_min} readOnly={true} /></td>
                  <td><input id="diaVital" name="dbp_min" className="sb-field form-control" value={item.dbp_min} readOnly={true} /></td>
                  <td><input id="hrVital" name="heart_rate_min" className="sb-field form-control" value={item.heart_rate_min} readOnly={true} /></td>
                  <td><input id="tempVital" name="temp_min" className="sb-field form-control" value={item.temp_min} readOnly={true} /></td>
                  <td><input id="resVital" name="resp_rate_min" className="sb-field form-control" value={item.resp_rate_min} readOnly={true} /></td>
                  <td><input id="satVital" name="oxygen_sat" className="sb-field form-control" value={item.oxygen_sat} readOnly={true} /></td>
                  <td><input id="heightVital" name="height" className="sb-field form-control" value={item.height} readOnly={true} /></td>
                  <td><input id="widthVital" name="weight" className="sb-field form-control" value={item.weight} readOnly={true} /></td>
                </tr>
                );
              })}
             </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <span className="sb-add-udi new-sb-attr widget-component-btn" onClick={() => this.openCreateModal()} >
            <i className="fas fa-plus-circle add-summary-btn fa-lg"></i>
            <span className="sb-add-text"> New Vital Signs</span>
          </span>
        </div>
      </div>
    );
  }
};

export default class VitalSigns extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Vital Signs",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: VitalSigns.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "vitalSignsComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <VitalSignsComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
