import React from "react"
import PropTypes from "prop-types"
import axios from "axios";



class State extends React.Component {

	 constructor(props) {
    super(props);
   
  }
  state = {
		  states: [],
	};

 componentDidMount() {
      // Runs after the first render() lifecycle
	  //   axios.get('/super_admin/states.json').then((response) => {
			// this.setState({
			//   states: response.data.states,
			// });
	  // });
	}
  render () {
  	const { states } = this.state;
    return (
      <div>
        States from ReactJS: 
         <select className="form-control">
            {
                states.map(stat => {
                    return (
                        <option key={stat.guid} value={stat.name}>
                            {stat.name}
                        </option>
                    )
                })
            }
        </select>
      </div>
    );
  }
}

State.propTypes = {
  greeting: PropTypes.string
};
export default State