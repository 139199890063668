import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./DrugAllergies.settingsForm";
import MedicationAllergyModal from "./MedicationAllergyModal";
// import DrugAllergiesImage from "images/widget/drug_allergies.png";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const DrugAllergiesComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      drugAllergies: {
        widget_data: [],
        nkda: props.nkda,
      },
      isModalOpen: false,
      selectedMedication: null,
    };
    this.props.onChange(this.state.drugAllergies);
  }

  getValue() {
    return this.state.drugAllergies;
  }

  setDrugAllergies = (drugAllergies) => {
    this.setState({ ...this.state, drugAllergies });
  }

  closeModal = () => {
    this.setState({ ...this.state, isModalOpen: false });
  }

  handleCheckboxChange = (event) => {
    const key = event.target.name;

    this.setState(
      (prevState) => ({
        ...prevState,
        drugAllergies: {
          ...prevState.drugAllergies,
          [key]: !prevState.drugAllergies[key],
        },
      }),
      () => {
        this.props.onChange(this.state.drugAllergies);
      }
    );
  };

  setValue(value) {
    if (value !== null) {
      var nkda = value?.widget_data.length > 0 ? false : value?.nkda;
      this.setState({
        ...this.state,
        drugAllergies: {
          widget_data: value?.widget_data || [],
          nkda: nkda,
        },
      });
    }
  }

  openEditModal = (data) => {
    if (data.item.can_edit) {
      this.setState({
        ...this.state,
        isModalOpen: true,
        selectedMedication: {
          id: data.item.widgetable_id,
          patient_guid: data.item.patient_guid,
          patient_form_guid: data.item.patient_form_guid,
          patient_form_entry_guid: data.item.guid,
          medication_name: data.item.medication_name,
          reaction: data.item.reaction,
          status: data.item.status
        },
      });
    }
  };

  openCreateModal = () => {
    this.setState({
      ...this.state,
      isModalOpen: true,
      selectedMedication: {
        patient_guid: document.getElementById("patient_guid").value,
        patient_form_guid: document.getElementById("patient_note_guid").value,
      },
    });
  };

  render() {
    $(".component-edit-container p.lead").html("Drug Allergies Widget");

    return (
      <>
        <div id={this.props.component.key}>
          <div className="float-right">
            <input
              className="form-check-input1 "
              type="checkbox"
              name="nkda"
              id="drugAllergiesNKDA"
              disabled={this.state.drugAllergies.widget_data.length > 0}
              checked={this.state.drugAllergies.nkda || false}
              onChange={(event) => this.handleCheckboxChange(event)}
            />
            <label className="form-check-label1" htmlFor="drugAllergiesNKDA">
              <h3 className="ml-1">NKDA</h3>
            </label>
          </div>

          <div className="drug-allergies-widget">
            <h3>Drug Allergies</h3>
            <table className="widget-table-border w-100">
              <thead className="sb-table-header">
                <tr>
                  <td>MEDICATION NAME</td>
                  <td>REACTION</td>
                  <td>STATUS</td>
                </tr>
              </thead>
              <tbody className="sb-table-body-reg_allergy-widget ">
                {this.state.drugAllergies &&
                  this.state.drugAllergies.widget_data.length > 0 &&
                  this.state.drugAllergies.widget_data.map((item) => {
                    return (
                      <tr
                        key={item.guid}
                        id={item.guid}
                        data-form-id={item.widgetable_id}
                        onClick={() => this.openEditModal({ item })}
                      >
                        <td>{item.medication_name}</td>
                        <td>{item.reaction}</td>
                        <td>{item.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <span
                className="sb-add-med-allergy widget-component-btn"
                data-attr-type="med_allergy"
                onClick={() => this.openCreateModal()}
              >
                <i className="fas fa-plus-circle add-summary-btn fa-lg"></i>
                <span className="sb-add-text">New Allergy</span>
              </span>
            </div>
          </div>
        </div>
        {
          this.state.isModalOpen && (
            <MedicationAllergyModal setDrugAllergies={this.setDrugAllergies} closeModal={this.closeModal} state={this.state} />
          )
        }
      </>
    );
  }
};

export default class DrugAllergies extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Drug Allergies",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: DrugAllergies.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "drugAllergiesComp",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <DrugAllergiesComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
