import React from "react"
import PropTypes from "prop-types"

class CreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      text: props.word,
    };
  }

  setValue = () => {
    this.setState(
      prevState => ({ value: !prevState.value }),
      () => this.props.onChange(null, this.state.value)
    );
  };

 onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.setState({text: this.props.word})
  }

  render () {
    console.log("Props", this.props)
    console.log("Props", this.state)

    return (
      <React.Fragment>
          <input type="text" value={this.state.text} onChange={this.onChange} />
      </React.Fragment>
    );
  }
}

CreateForm.propTypes = {
  greeting: PropTypes.string
};
export default CreateForm
