import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./ExplanatoryText.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ExplanatoryTextCustomComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      text_bold: "",
      text_italic: "",
      text_underline: "",
      text_border: "",
    };
  }

  componentDidMount() {
    if (this.state.component.textBold){
      this.setState({text_bold: "text-bold"});
    }
    if (this.state.component.textItalic){
      this.setState({text_italic: "text-italic"});
    }
    if (this.state.component.textUnderline){
      this.setState({text_underline: "text-underline"});
    }
    if (this.state.component.textBorder){
      this.setState({text_border: "border border-dark"});
    }
  }

  render() {
    $(".component-edit-container p.lead").html("Explanatory Text");
    var padding_class = '';
    if (this.state.component.textBorder){
      var padding_class = 'p-2';
    }
    return (
      <div id={this.state.component.key}>
        <p className={`${padding_class} ${this.state.component.textAlign}  ${this.state.component.colorTheme} ${this.state.text_bold} ${this.state.text_italic} ${this.state.text_underline} ${this.state.text_border}`} style={{fontSize: this.state.component.textSize, whiteSpace: "pre-wrap"}}>
          {this.state.component.text}
        </p>
      </div>
    );
  }
};

export default class ExplanatoryText extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Explanatory Text",
      icon: "text-height",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: ExplanatoryText.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "explanatoryTextCustomComp",
      textAlign: "text-left",
      textSize: "12",
      colorTheme: "bg-white",
      textBold: "",
      textItalic: "",
      textUnderline: "",
      textBorder: ""
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <ExplanatoryTextCustomComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
