import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./Email.settingsForm";

export default class Email extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Email",
      icon: "at",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Email.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "emailCustomComp",
      emailLabel: "",
      labelAlign: "text-left",
      colorTheme: "bg-white",
    });
  }

  static editForm = settingsForm;

  validateEmail(value) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var is_validated = regex.test(value);
    if (value == ""){
      is_validated = true;
    }
    if (is_validated){
      $(`input#${this.component.key}-emailInput`).removeClass("has-error");
      $(`input#${this.component.key}-emailInput`).closest(".formio-component-emailCustomComp").removeClass("has-error");
      $(`input#${this.component.key}-emailInput`).closest(".formio-component-emailCustomComp").find(".formio-errors").html("");
      return value;
    }
    else{
      $(`input#${this.component.key}-emailInput`).addClass("has-error");
      $(`input#${this.component.key}-emailInput`).closest(".formio-component-emailCustomComp").addClass("has-error");
      $(`input#${this.component.key}-emailInput`).closest(".formio-component-emailCustomComp").find(".formio-errors").html("Email must be a valid email.");
      return "";
    }
  }

  render(children) {

    $(".component-edit-container p.lead").html("Email");

    let email_label = "";
    email_label += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    email_label += `${this.component.emailLabel || ''}`;
    email_label += `</span>`;

    var emailInput = ''
    var attr_json = {
                      id: `${this.component.key}-emailInput`,
                      class: `line-input w-100 ${this.component.requiredSaveNote ? 'required' : ''}`,
                      type: 'email',
                      placeholder: `Type email address here`
                    }
    if (this.component.requiredSaveNote){
      attr_json.required = 'required'
    }
    var emailInput = this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-emailInput`,
        attr: attr_json
      }
    });

    return super.render(`
      <div id="${this.component.key}" class="${this.component.colorTheme}">
        <div class="${this.component.labelAlign}">
          ${email_label}
        </div>
        <div class="form-group">
          ${emailInput}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}-emailInput`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}-emailInput`][0], 'keyup', () => this.updateValue())
    
    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}-emailInput`][0].value;
    var new_value = this.validateEmail(value);
    $(`input#${this.component.key}-emailInput`).attr("value", new_value);
    return new_value;
  }

  setValue(value) {
    this.refs[`${this.component.key}-emailInput`][0].value = value;
    $(`input#${this.component.key}-emailInput`).attr("value", value);
  }

}
