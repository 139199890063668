import React from "react";
import PropTypes from "prop-types"
import { FormBuilder, Components, Form } from "@formio/react";
import components from "./Custom";
import axios from "axios";
import { useState } from 'react';

Components.setComponents(components);
let myform;

class RenderAssessmentTool extends React.Component {

    constructor(props) {
    super(props);
    var data = props.form_builder_data.value || {};
    const form = JSON.parse(props.form_builder.content);
    const name = props.form_builder.name;

    this.state = {
      form: form,
      data: data,
      name: name
    };
  }

    
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.cleanup);
  }

  
  submitFormData (schema, patientFormGuid, formGuid) { 
    validateForm("false");
    var data = schema.data ;
    console.log('submitFormData', data)
    // this.setState({result: data}); 
    // this.setState({submitted: false}); 

    axios
      .post(
        `/assessment_tools/submission?patient_form_guid=${patientFormGuid}&form_guid=${formGuid}`,
        { data }
      )
      .then((res) => {
        if (res.data){
        
        }
        else {
          swal("Error", "There was issue with saving this note. Please relogin and reopen this note.", 'error')
        }
      })
      .catch((error) => {
        swal("Error", "There was issue with saving this note. Please relogin and reopen this note.", 'error')
      });
 
  }

  saveFormData (data,patientFormGuid,formGuid) {
    console.log('saveFormData', data)
    // this.setState({setSubmitted: true});
    // this.setState({submitted: true}); 
    // var hiddenInputs = document.getElementById("hiddenFieldCustomComp-hiddenFieldSingleLine");
    var hiddenInputs = document.getElementsByClassName('assessment-tool-hidden-input');
    hiddenInput = hiddenInputs[0];
    hiddenInput.value = new Date();
    var event = new Event('keyup', { bubbles: true});
    hiddenInput.dispatchEvent(event);
     
  }



  render () {
   const { form, data, name } = this.state;

    var form_json_data = JSON.parse(this.props.form_builder.content);
    var form_builder_data = {};
    if (this.props.form_builder_data){
      form_builder_data = this.props.form_builder_data.value
    }
    const patientFormGuid = this.props.patient_form_guid
    const formGuid = this.props.form_builder.guid
    // const { result, setResult, submitted } = this.state;
    const submitted = false;
    form_json_data["components"].push(
    {
      "type": "button",
      "label": "Submit",
      "key": "submit",
      "disableOnInvalid": false,
      "input": true,
      "tableView": false,
      "customClass": "assessment-tool-sbt-btn float-right d-none"
      });
    return (
      <div>
        <div>Assessment Tool</div>
        <Form
        	form={form_json_data}
          submission={{data: data}}
          formReady = {(instance) => 
                          {
                            instance.on('change', (schema) => {
                                if (schema && schema.changed && schema.data){
                                  this.submitFormData(schema, patientFormGuid, formGuid) 
                                }
                                this.formRef = instance;
                            })
                          }
                        }
        	
      	/>
      </div>
    );
  }
}

RenderAssessmentTool.propTypes = {
};

export default RenderAssessmentTool
