// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
var debounce = require('lodash.debounce');

// require("packs/jquery.ptTimeSelect")
// require("packs/bootstrap-datepicker")

// import "../../../vendor/assets/javascripts/bootstrap.min.js";
// import "../../../vendor/assets/javascripts/bootstrap-select.js";
// import "../../../vendor/assets/javascripts/ajax-bootstrap-select.min.js";
// import "../../../vendor/assets/javascripts/sweetalert.min.js";
import ahoy from "ahoy.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
//Notification.requestPermission().then(function (result) {})

function changeValue(input,value){

    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input, value);

    var inputEvent = new Event("input", { bubbles: true });
    input.dispatchEvent(inputEvent);
}