import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./MedicationList.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';
import actionmedsImage from 'images/actionmeds.svg'
import editmedsImage from 'images/med-edit.svg'

import handwrittenMessage3Image from 'images/handwritten_message3.svg'

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const MedicationListComp = class extends Component { 

   constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      medicationList: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.medicationList);
  }
  getValue() {
    return this.state.medicationList;
  }

  handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const key = name;
    this.setState(
      (prevState) => ({
        medicationList: {
          ...prevState.medicationList,
          [key]: !prevState.medicationList[key],
        },
      }),
      () => {
        this.props.onChange(this.state.medicationList);
      }
    );
  };
  setValue(value) {
    if (value !== null) {
      this.setState({
        medicationList: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }
  openEditModal = (data) => {
    if(data.item.can_edit){
       $.post('/form_builders/widgets/new_edit_sb_attr', { 
        attr_type: 'medication_list',
        patient_guid: data.item.patient_guid,
        id: data.item.widgetable_id,
        patient_form_guid: data.item.patient_form_guid,
        patient_form_entry_guid: data.item.guid
      }); 
    }  
  }

  openCreateModal = () =>{
    $.post('/form_builders/widgets/new_edit_sb_attr', {
      attr_type: 'medication_list',
      patient_guid: $('#patient_guid').val(),
      patient_form_guid: $('#patient_note_guid').val(),
      tab: 'notes'
       })
      
  }

  openScriptSure = () =>{
    window.open(`/orders/prescriptions?cid=${$('#patient_guid').val()}&ref=notes_to_meds`, 'window name', 'window settings')
  }

  render() {
    $(".component-edit-container p.lead").html("Medication List");
    return (
      <div id={this.props.component.key}>
        <div className="medication-list-widget">
          <h3>MEDICATION LIST</h3>
          <table className="widget-table-border w-100">
            <thead className="sb-table-header">
              <tr className="text-center">
                <td colSpan="2">
                  MEDICATION
                  <div className="float-right">
                    <span className="sb-add-med-allergy pointer widget-component-btn" onClick={() => this.openScriptSure()}>
                      <img src={handwrittenMessage3Image} />
                      &nbsp;<span className="sb-add-text"> EPRESCRIBE</span>
                    </span>
                    <span className="sb-add-med-allergy pointer widget-component-btn" data-attr-type="medication_list" onClick={() => this.openCreateModal()}>
                      <i className="fas fa-plus-circle add-summary-btn fa-lg"></i>
                      &nbsp;<span className="sb-add-text"> Add Medication</span>
                    </span>
                  </div>
                </td>         
              </tr>
            </thead>
              <tbody className="sb-table-body">
               {this.state.medicationList  && this.state.medicationList.widget_data.length > 0 && this.state.medicationList.widget_data.map(item => {
                return (
                  <tr key={item.guid} id={item.guid} data-form-id={item.widgetable_id} >
                    <td>
                      {
                        <span className={item.action == 'stop' ? 'text-danger' : ''}>
                          {item.action == 'stop' ? 'Stopped ' : ''}
                        </span>
                      }
                      <span><b> { item.medication_label }</b></span>
                      <span>{ item.dose } </span>
                      <span>{ item.sig }  </span>
                    </td>
                    <td 
                      style={{width: '65px'}}
                      className={item.can_edit ? '' : 'd-none'}
                    >
                      <span 
                        className={`p_med_status_update float-left text-danger ${item.can_edit ? '' :  'd-none'} `} style={{width: '20px'}} data-id={item.widgetable_id}
                        data-action="notes"
                        data-note-guid={item.patient_form_guid}
                        data-patient-form-entry-guid={item.guid}>
                        <img title="Active" width="20" src={actionmedsImage} />
                      </span>
                       

                      <span 
                        style={{width: '20px', paddingLeft: '8px'}}
                        className={item.action == 'stop' ? 'd-none' : ''}
                        >
                        <a 
                          data-remote="true"
                          href={`/meds/${item.widgetable_id}/edit?patient_form_guid=${item.patient_form_guid}&tab=notes`}
                          className={item.can_edit ? '' : 'd-none'}
                           >
                          <img title="Edit" width="20" src={editmedsImage} />
                        </a>
                      </span>

              
                    </td>
                  </tr>
                );
              })}
             </tbody>
          </table>
          <div id="med_status_change"></div>
          <div id="form_select_containter"></div>
          <div id="prn_med_status_change"></div>
        </div>
      </div>
    );
  }
};

export default class MedicationList extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Medication List",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: MedicationList.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "medicationListComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <MedicationListComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
