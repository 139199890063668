import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./NonDrugAllergies.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';
import NonDrugAllergiesImage from 'images/widget/non_drug_allergies.png'

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const NonDrugAllergiesComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      nonDrugAllergies: {
        widget_data: [],
        none: false,
      },
    };
    this.props.onChange(this.state.nonDrugAllergies);
  }
  getValue() {
    return this.state.nonDrugAllergies;
  }

  handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const key = name;

    this.setState(
      (prevState) => ({
        nonDrugAllergies: {
          ...prevState.nonDrugAllergies,
          [key]: !prevState.nonDrugAllergies[key],
        },
      }),
      () => {
        this.props.onChange(this.state.nonDrugAllergies);
      }
    );
  };
  setValue(value) {
    if (value !== null) {
      var none = value?.widget_data.length > 0 ? false : value?.none
      this.setState({
        nonDrugAllergies: {
          widget_data: value?.widget_data || [],
          none: none,
        },
      });
    }
  }

  openEditModal = (data) => {
    if(data.item.can_edit){
       $.post('/form_builders/widgets/new_edit_sb_attr', { 
        attr_type: 'reg_allergy',
        patient_guid: data.item.patient_guid,
        id: data.item.widgetable_id,
        patient_form_guid: data.item.patient_form_guid,
        patient_form_entry_guid: data.item.guid
      }); 
    }   
  }

  openCreateModal = () =>{
   $.post('/form_builders/widgets/new_edit_sb_attr', {
    attr_type: 'reg_allergy',
    patient_guid: $('#patient_guid').val(), patient_form_guid: $('#patient_note_guid').val() });
  }

  render() {
    $(".component-edit-container p.lead").html("Non Drug Allergies Widget");
    return (
      <div id={this.state.component.key}>
        <div className="float-right">
          <input
            className="form-check-input1 "
            type="checkbox"
            name="none"
            id="nonDrugAllergiesNone"
            disabled={this.state.nonDrugAllergies.widget_data.length > 0}
            checked={
              this.state.nonDrugAllergies.none
            }
            onChange={(event) => this.handleCheckboxChange(event)}
          />
          <label
            className="form-check-label1 bold"
            htmlFor="nonDrugAllergiesNone"
          >
            <h3 className="ml-1">NONE</h3>
          </label>
        </div>
        <div className="non-drug-allergies-widget">
          <h3>Non Drug Allergies</h3>
          <table className="widget-table-border w-100">
            <thead className="sb-table-header">
              <tr>
                <td key="0" >ALLERGY NAME</td>
                <td key="1" >REACTION</td>
             </tr>
            </thead>
             <tbody className="sb-table-body-reg_allergy-widget ">
               {this.state.nonDrugAllergies  && this.state.nonDrugAllergies.widget_data.length > 0 && this.state.nonDrugAllergies.widget_data.map(item => {
                return (
                  <tr key={item.guid} id={item.guid} data-form-id={item.widgetable_id} onClick={() => this.openEditModal({item})} >
                    <td>{ item.allergy_name }</td>
                    <td>{ item.reaction }</td>
                  </tr>
                );
              })}
             </tbody>
         </table>
          <div className="d-flex justify-content-end">
            <span className="sb-add-med-allergy widget-component-btn" data-attr-type="med_allergy" onClick={() => this.openCreateModal()} >
              <i className="fas fa-plus-circle add-summary-btn fa-lg"></i>
              <span className="sb-add-text"> New Allergy</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default class NonDrugAllergies extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Non Drug Allergies",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: NonDrugAllergies.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "nonDrugAllergiesComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <NonDrugAllergiesComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

   getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}