import React from "react";
import PropTypes from "prop-types"
import { FormBuilder, Components, Form } from "@formio/react";
import components from "./Custom";
import axios from "axios";

Components.setComponents(components);

class AssessmentToolReport extends React.Component {

  constructor(props) {
    super(props);
    var data = props.form_builder_data.value || {};
    const form = JSON.parse(props.form_builder.content);
    const name = props.form_builder.name;

    this.state = {
      form: form,
      data: data,
      name: name,
    };
  }

  scoreStyleClasses(scoreComponent){
    var scoreClasses = (`${scoreComponent.textAlign} ${scoreComponent.colorTheme} ${scoreComponent.textBold ? 'text-bold' : ''} ${scoreComponent.textItalic ? 'text-italic' : ''} ${scoreComponent.textUnderline ? 'text-underline' : ''} ${scoreComponent.textBorder ? 'border border-dark' : ''}`)
    return scoreClasses;
  }
  IsInRange(row, number){
    var match = ''
      if  (number >= row.start && number <= row.end){
        match = row
      }
    if (match != ''){
      return `${match.description}`
    }
  }

  calculateScore(grouped, scoreComponent, scoreTye){
    var score = 0;
    var scoreObj = [];
    if(grouped){
    
      // console.log('calculateScore', grouped)
      var keys = Object.keys(grouped)
       keys.forEach(function (key) { 
        var value = grouped[key]
         console.log('Grouped Key is: ', key);
         console.log('Grouped Value is: ', value);
      });
       console.log('scoreComponent', scoreComponent)
      var values = Object.values(grouped)
      if (grouped[scoreComponent.groupName]){
        scoreObj = grouped[scoreComponent.groupName]
      } else {
        if (grouped[keys[0]]){
          scoreObj = grouped[keys[0]]
        }
      }
      for (let i = 0; i < scoreObj.length; i++) {

        if (scoreTye == "addition"){
         score += scoreObj[i].sum
        }
        if (scoreTye == "average"){
         score += scoreObj[i].average
        }
        if (scoreTye == "total"){
         score += scoreObj[i].total
        }
      }
    }
    console.log("Score", score)
    return score;
  }

  render () {
    const { form, data, name } = this.state;

    let scoreComponents = []
    let assessmentComponents = []
    let sameGroupScoreComponents = []

    let ansArray = [];
    let groupArray = []
    var grouped = [];
    var average = 0.0;
    var sum = '';
    var total = 0.0;

    for (let f = 0; f < form.components.length; f++) {
       if(form.components[f].type == "weightedScoreComp"){
            scoreComponents.push(form.components[f]);
          }
      if(form.components[f].type == "assessmentToolComp" || form.components[f].type == "assessmentToolTabularComp"){
        assessmentComponents.push(form.components[f]);
      }
    }
    // console.log('scoreComponents', scoreComponents)


    assessmentComponents.forEach(function(component) {
         console.log('assessmentComponents component', component);
          average = 0.0;
          sum = 0.0;
          total = 0.0;
          ansArray = [];
          var radioWeights = [];

          // for (let i = 0; i < component.tableColumns.length; i++) {
          //   for (let j = 0; j < component.tableRows.length; j++) {
          //     //remove first column count
          //     if(i != 0){
          //      total += component.tableColumns[i].radioWeight;
          //     }
          //   }
          // }
          Array.prototype.max = function() {
            return Math.max.apply(null, this);
          };

          Array.prototype.min = function() {
            return Math.min.apply(null, this);
          };
          if(component.type == 'assessmentToolComp'){
            for (let i = 0; i < component.tableColumns.length; i++) {
              //remove first column count
              if(i != 0){
               radioWeights.push(component.tableColumns[i].radioWeight);
              }
            }
            var maxWeight = radioWeights.max();
            total = maxWeight * component.tableRows.length
          }
          if(component.type == 'assessmentToolTabularComp'){
            for (let i = 0; i < component.radioButtonsColumns.length; i++) {
               radioWeights.push(component.radioButtonsColumns[i].radioWeight);
            }
            // radioWeights.push(component.radioButtonsColumns[0].radioWeight);
            var maxWeight = radioWeights.max();
            total += maxWeight
          }
          

          if (data[component['key']]){
            for (let i = 0; i < data[component['key']].length; i++) {
              for (let j = 0; j < data[component['key']][i].length; j++) {
                if (!(data[component['key']][i][j] === false)){
                  ansArray.push(parseFloat(data[component['key']][i][j]));
                }
              }
            }
            sum = eval(ansArray.join("+"));
            average = parseFloat((sum /  data[component['key']].length));
            groupArray.push({group: component.groupName, sum: sum, average: average, total: total })
          }
      });

    grouped = _.groupBy(groupArray, function(x){return x.group})
    console.log("grouped", grouped)
    return (
      <div>
        <Form
        	form={form}
          submission={{data: data}}
          readOnly= {true}
      	/>
        {scoreComponents.map(scoreComponent => {
          return (
              <div key={scoreComponent.id}>
                
                <span className="d-none">{scoreComponent.groupName}</span>
                <span className="d-none">{scoreComponent.scoreType}</span>
                  {
                    scoreComponent.scoreType == "addition" &&
                    <div>
                    <p 
                      className={this.scoreStyleClasses(scoreComponent)}
                      style={{fontSize: scoreComponent.textSize, whiteSpace: "pre-wrap"}}
                      >
                      {scoreComponent.text.replace("${score}", this.calculateScore(grouped, scoreComponent, 'addition')).replace("${total}", this.calculateScore(grouped, scoreComponent, 'total'))}
                    </p>
                    </div>

                  }

                  {
                    scoreComponent.scoreType == "average" &&
                    <div>
                      <p 
                        className={this.scoreStyleClasses(scoreComponent)}
                        style={{fontSize: scoreComponent.textSize, whiteSpace: "pre-wrap"}}
                      >
                        {scoreComponent.text.replace("${score}", this.calculateScore(grouped, scoreComponent, 'average')).replace("${total}", this.calculateScore(grouped, scoreComponent, 'total'))}
                      </p>
                    </div>
                  }
                  {
                    scoreComponent.tableColumns && scoreComponent.tableColumns.map(tableColumn => {
                      return (
                        <div key={scoreComponent.groupName}>
                          {
                            
                              scoreComponent.scoreType == "addition" && this.IsInRange(tableColumn, this.calculateScore(grouped, scoreComponent, 'addition')) &&
                              <p 
                                className={this.scoreStyleClasses(scoreComponent)}
                                style={{fontSize: scoreComponent.textSize, whiteSpace: "pre-wrap"}}

                              >
                                {this.IsInRange(tableColumn, this.calculateScore(grouped, scoreComponent, 'addition'))}
                              </p>
                          }
                          {
                            
                              scoreComponent.scoreType == "average" && this.IsInRange(tableColumn, this.calculateScore(grouped, scoreComponent, 'average')) &&
                               <p 
                                 className={this.scoreStyleClasses(scoreComponent)}
                                 style={{fontSize: scoreComponent.textSize, whiteSpace: "pre-wrap"}}

                                > 
                                  {this.IsInRange(tableColumn, this.calculateScore(grouped, scoreComponent, 'average'))}
                                </p>
                          }
                        </div>
                      )
                     })

                    }
              </div>
            );

          })}
       

        {form.components.map(component => {
          return (
            <div>
            {
              component.type == 'assessmentToolComp' &&
              ( <div key={component.key} id={component.key} >
                    {component['checkBoxesColumns'] && component['checkBoxesColumns'].map(({checkBoxesColumn, index}) => (
                      <div key={index}>
                      <li>{checkBoxesColumn['checkBoxText']}</li>
                      <li>{checkBoxesColumn['checkBoxWeight']}</li>
                      </div>

                    ))}
                </div>
              )
            }
            </div>
          );
        })}

      </div>
    );
  }
}

AssessmentToolReport.propTypes = {
};

export default AssessmentToolReport
