import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Demographic.settingsForm";
import axios from 'axios';
import $ from 'jquery';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const DemographicComp = class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      can_edit: true,
      states: [],
      genders: [],
      gender_identities: [],
      sexual_orientations: [],
      races: [],
      ethnicities: [],
      languages:[],
      relationships: [],
      demographicInfo: {
        firstname: '',
        lastname: '',
        preferred_firstname: '',
        preferred_lastname: '',
        pronoun: '',
        dob: '',
        ssn: '',
        email: '',
        cell_phone: '',
        work_phone: '',
        home_phone: ''
      },
      guardianInfo: {
        firstname: '',
        lastname: '',
        email: '',
        street_address: '',
        street_address_2: '',
        city: '',
        state: '',
        zipcode: '',
        cell_phone: '',
        work_phone: '',
        home_phone: '',
        gender: ''
      },
      guardianInfo2: {
        firstname: '',
        lastname: '',
        email: '',
        street_address: '',
        street_address_2: '',
        city: '',
        state: '',
        zipcode: '',
        cell_phone: '',
        work_phone: '',
        home_phone: '',
        gender: ''
      },
      clientAddress: {
        street_address: '',
        street_address_2: '',
        city: '',
        state: '',
        zipcode: ''
      },
       personalInfo: {
        gender: '',
        gender_identity: '',
        sexual_orientation: '',
        race: '',
        ethnicity: '',
        language: '',
      },
      primaryContactInfo: {
        firstname: '',
        lastname: '',
        relationship: '',
        dob: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        email: ''
      },
      additionalClientInfo: {
        street_address: '',
        street_address_2: '',
        city: '',
        state: '',
        zipcode: '',
        phone: ''
      },
      emergencyContactInfo: {
        firstname: '',
        lastname: '',
        relationship: '',
        dob: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        email: ''
      },
      guarantorInfo: {
        firstname: '',
        lastname: '',
        relationship: '',
        gender: '',
        dob: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        email: ''
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    alert('The value is: ' + this.input.value);
    e.preventDefault();
  }

  componentDidMount() {
    console.log('componentDidMount CALLED')
    var activeTab = $('div.tab-pane-full.active');
    var activeForm = activeTab.find('form.autosave-form');
    var savedId = activeForm.parent().parent().attr('data-saved-id');
          // Runs after the first render() lifecycle
    axios.get(`/super_admin/states.json?patient_form_guid=${savedId}`).then((response) => {
      console.log('response.data', response.data)
      if (response.data && response.data.demographic_info){
        this.setState({
          states: response.data.states,
          genders: response.data.genders,
          gender_identities: response.data.gender_identities,
          sexual_orientations: response.data.sexual_orientations,
          races: response.data.races,
          ethnicities: response.data.ethnicities,
          languages: response.data.languages,
          relationships: response.data.relationships,
          can_edit: response.data.can_edit,
          demographicInfo: {
            firstname: response.data.demographic_info.firstname,
            lastname: response.data.demographic_info.lastname,
            preferred_firstname:
              response.data.demographic_info.preferred_firstname,
            preferred_lastname:
              response.data.demographic_info.preferred_lastname,
            pronoun: response.data.demographic_info.pronoun,
            dob: response.data.demographic_info.dob,
            ssn: response.data.demographic_info.ssn,
            email: response.data.demographic_info.email,
            cell_phone: response.data.demographic_info.phone,
            work_phone: response.data.demographic_info.work_phone,
            home_phone: response.data.demographic_info.home_phone,
          },
          guardianInfo: {
            firstname: response.data.client_guardian.firstname,
            lastname: response.data.client_guardian.lastname,
            cell_phone: response.data.client_guardian.cell_phone,
            work_phone: response.data.client_guardian.work_phone,
            home_phone: response.data.client_guardian.home_phone,
            street_address: response.data.client_guardian.street_address,
            street_address_2: response.data.client_guardian.street_address_2,
            zipcode: response.data.client_guardian.zipcode,
            city: response.data.client_guardian.city,
            dob: response.data.client_guardian.dob,
            email: response.data.client_guardian.email,
            state: response.data.client_guardian.state,
            rank: response.data.client_guardian.rank,
            gender: response.data.client_guardian.gender,
          },
          guardianInfo2: {
            firstname: response.data.client_guardian2.firstname,
            lastname: response.data.client_guardian2.lastname,
            cell_phone: response.data.client_guardian2.cell_phone,
            work_phone: response.data.client_guardian2.work_phone,
            home_phone: response.data.client_guardian2.home_phone,
            street_address: response.data.client_guardian2.street_address,
            street_address_2: response.data.client_guardian2.street_address_2,
            zipcode: response.data.client_guardian2.zipcode,
            city: response.data.client_guardian2.city,
            dob: response.data.client_guardian2.dob,
            email: response.data.client_guardian2.email,
            state: response.data.client_guardian2.state,
            rank: response.data.client_guardian2.rank,
            gender: response.data.client_guardian2.gender,
          },
          clientAddress: {
            street_address: response.data.client_address.street_address,
            street_address_2: response.data.client_address.street_address_2,
            zipcode: response.data.client_address.zipcode,
            city: response.data.client_address.city,
            state: response.data.client_address.state,
          },
          personalInfo: {
            gender: response.data.personal_info.gender,
            gender_identity: response.data.personal_info.gender_identity,
            sexual_orientation: response.data.personal_info.sexual_orientation,
            race: response.data.personal_info.race,
            ethnicity: response.data.personal_info.ethnicity,
            language: response.data.personal_info.language,
          },
          primaryContactInfo: {
            firstname: response.data.primary_contact_info.firstname,
            lastname: response.data.primary_contact_info.lastname,
            relationship: response.data.primary_contact_info.relationship,
            dob: response.data.primary_contact_info.dob,
            address: response.data.primary_contact_info.street_address,
            city: response.data.primary_contact_info.city,
            state: response.data.primary_contact_info.state,
            zipcode: response.data.primary_contact_info.zipcode,
            phone: response.data.primary_contact_info.phone,
            email: response.data.primary_contact_info.email,
            state: response.data.primary_contact_info.state,
          },
          additionalClientInfo: {
            street_address:
              response.data.additional_contact_info.street_address,
            street_address_2:
              response.data.additional_contact_info.street_address_2,
            city: response.data.additional_contact_info.city,
            state: response.data.additional_contact_info.state,
            zipcode: response.data.additional_contact_info.zipcode,
            phone: response.data.additional_contact_info.phone,
          },
          emergencyContactInfo: {
            firstname: response.data.emergency_secondary_contact.firstname,
            lastname: response.data.emergency_secondary_contact.lastname,
            relationship:
              response.data.emergency_secondary_contact.relationship,
            dob: response.data.emergency_secondary_contact.dob,
            address: response.data.emergency_secondary_contact.street_address,
            city: response.data.emergency_secondary_contact.city,
            state: response.data.emergency_secondary_contact.state,
            zipcode: response.data.emergency_secondary_contact.zipcode,
            phone: response.data.emergency_secondary_contact.phone,
            email: response.data.emergency_secondary_contact.email,
          },
          guarantorInfo: {
            firstname: response.data.patient_guarantor.firstname,
            lastname: response.data.patient_guarantor.lastname,
            relationship: response.data.patient_guarantor.relationship,
            gender: response.data.patient_guarantor.gender,
            dob: response.data.patient_guarantor.dob,
            address: response.data.patient_guarantor.street_address,
            city: response.data.patient_guarantor.city,
            state: response.data.patient_guarantor.state,
            zipcode: response.data.patient_guarantor.zipcode,
            phone: response.data.patient_guarantor.phone,
            email: response.data.patient_guarantor.email,
          },
        });
      }  
    });
  }

  onChangeEvent = (event) => {
    this.setState((prevState) => ({
      // demographicInfo: {
      //   firstname: event.target.value,
      //   lastname: event.target.value,
      //   preferred_firstname: event.target.value,
      //   preferred_lastname: event.target.value,
      //   dob: event.target.value,
      //   ssn: event.target.value,
      //   cell_phone: event.target.value,
      //   work_phone: event.target.value,
      //   home_phone: event.target.value
      // },
      // guardianInfo:{
      //   firstname: event.target.value,
      //   lastname: event.target.value,
      //   cell_phone: event.target.value,
      //   work_phone: event.target.value,
      //   home_phone: event.target.value,
      //   street_address: event.target.value,
      //   street_address_2: event.target.value,
      //   zipcode: event.target.value,
      //   city: event.target.value,
      // },
      // clientAddress:{
      //   street_address: event.target.value,
      //   street_address_2: event.target.value,
      //   zipcode: event.target.value,
      //   city: event.target.value,
      //   state: event.target.value
      // },
      // personalInfo:{
      //   gender: '',
      //   gender_identity: '',
      //   sexual_orientation: '',
      //   race: '',
      //   ethnicity: ''
      // },
      // primaryContactInfo: {
      //   firstname: event.target.value,
      //   lastname: event.target.value,
      //   relationship: event.target.value,
      //   dob: event.target.value,
      //   address: event.target.value,
      //   city: event.target.value,
      //   state: event.target.value,
      //   zipcode: event.target.value,
      //   phone: event.target.value,
      //   email: event.target.value
      // }
    }));
  }

  render() {
    $(".component-edit-container p.lead").html("Demographic Widget");
    const { states, genders, gender_identities, sexual_orientations, races ,ethnicities, languages, demographicInfo, guardianInfo, guardianInfo2, clientAddress, primaryContactInfo, additionalClientInfo, emergencyContactInfo, guarantorInfo, relationships, personalInfo } = this.state;
    return (
      <div id={this.state.component.key} className="demographic-info cm-enable">
        <div
          id="demographicInfo"
          className={`demographic-component-widget ${
            this.state.component.demographicInfo ? "show" : "d-none"
          }`}
        >
          <h3 className="bold">Main Demographic Info:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.firstandlastName ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={this.state.demographicInfo.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  name="name"
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="demographicInfoFirstName"
                  ref="demographicInfoFirstName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.firstandlastName ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={this.state.demographicInfo.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="demographicInfoLastName"
                  ref="demographicInfoLastName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.preferredFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">
                  Preferred First Name
                </label>
                <input
                  value={this.state.demographicInfo.preferred_firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Preferred First Name"
                  type="text"
                  className="form-control w-100"
                  id="preferredFirstnamedemographicComp"
                  ref="preferredFirstnamedemographicComp"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.preferredFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">
                  Preferred Last Name
                </label>
                <input
                  value={this.state.demographicInfo.preferred_lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Preferred Last Name"
                  type="text"
                  className="form-control w-100"
                  id="preferredLastnamedemographicComp"
                  ref="preferredLastnamedemographicComp"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.pronoun ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Preferred Pronoun</label>
                <input
                  value={
                    this.state.demographicInfo.pronoun || ""
                  }
                  placeholder="Pronoun"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="pronoun"
                  ref="pronoun"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.dob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={demographicInfo.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  type="text"
                  name="demographicInfodob"
                  id="demographicInfodob"
                  required="required"
                  className="form-control"
                  ref="demographicInfodob"
                  placeholder="mm/dd/yyyy"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.socialSecurity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Social Security</label>
                <input
                  value={this.state.demographicInfo.ssn}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Social Security"
                  type="text"
                  className="form-control w-100"
                  id="socialSecuritydemographicComp"
                  ref="socialSecuritydemographicComp"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.demographicInfoEmail ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={this.state.demographicInfo.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="text"
                  className="form-control w-100"
                  id="demographicInfoEmail"
                  ref="demographicInfoEmail"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.cellPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Phone</label>
                <input
                  value={demographicInfo.cell_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="cellPhonedemographicComp"
                  id="cellPhonedemographicComp"
                  className="form-control"
                  placeholder="Cell Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.workPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Home Phone</label>
                <input
                  value={demographicInfo.home_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="workPhonedemographicComp"
                  id="workPhonedemographicComp"
                  className="form-control"
                  placeholder="Home Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.homePhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Work Phone</label>
                <input
                  value={demographicInfo.work_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="homePhonedemographicComp"
                  id="homePhonedemographicComp"
                  className="form-control"
                  placeholder="Work Phone"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.demographicInfo && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="demographicInfo"
                className="demographic-info-update pointer float-right cm-enable"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="guardianInfo"
          className={`demographic-component-widget ${
            this.state.component.guardianInfo ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Guardian Info:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfofirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={this.state.guardianInfo.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="guargianInfoFirstName"
                  ref="guargianInfoFirstName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfofirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={this.state.guardianInfo.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="guargianInfoLastName"
                  ref="guargianLastName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfodob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={guardianInfo.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  type="text"
                  name="guardianInfodob"
                  id="guardianInfodob"
                  required="required"
                  className="form-control"
                  ref="dob"
                  placeholder="mm/dd/yyyy"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfoEmail ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={this.state.guardianInfo.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="email"
                  className="form-control w-100"
                  id="guardianInfoEmail"
                  ref="guardianInfoEmail"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfoaddress1 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 1</label>
                <input
                  value={this.state.guardianInfo.street_address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 1"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfoaddress1"
                  ref="guardianInfoaddress1"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfoaddress2 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 2</label>
                <input
                  value={this.state.guardianInfo.street_address_2}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 2"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfoaddress2"
                  ref="guardianInfoaddress2"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfoGender ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Gender</label>
                <input
                  value={guardianInfo.gender}
                  placeholder="Gender"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="guardianInfoGender"
                  ref="guardianInfoGender"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfocity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={this.state.guardianInfo.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfocity"
                  ref="guardianInfocity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfostate ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={guardianInfo.state}
                  type="text"
                  placeholder="State"
                  className="form-control"
                  id="guardianInfostate"
                  ref="guardianInfostate"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfozipcode ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={this.state.guardianInfo.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="guardianInfozipcode"
                  ref="guardianInfozipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfocellPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Cell Phone</label>
                <input
                  value={this.state.guardianInfo.cell_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfocellPhone"
                  id="guardianInfocellPhone"
                  className="form-control"
                  placeholder="Cell Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfoworkPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Work Phone</label>
                <input
                  value={this.state.guardianInfo.work_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfoworkPhone"
                  id="guardianInfoworkPhone"
                  className="form-control"
                  placeholder="Work Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfohomePhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Home Phone</label>
                <input
                  value={this.state.guardianInfo.home_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfohomePhone"
                  id="guardianInfohomePhone"
                  className="form-control"
                  placeholder="Home Phone"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.guardianInfo && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="guardianInfo"
                id="guardianInfoUpdate"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="guardianInfo2"
          className={`demographic-component-widget ${
            this.state.component.guardianInfo2 ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Guardian Info 2:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2firstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={this.state.guardianInfo2.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="guargianInfo2FirstName"
                  ref="guargianInfo2FirstName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2firstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={this.state.guardianInfo2.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="guargianInfo2LastName"
                  ref="guargianInfo2LastName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2dob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={guardianInfo2.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  type="text"
                  name="guardianInfo2dob"
                  id="guardianInfo2dob"
                  required="required"
                  className="form-control"
                  ref="dob"
                  placeholder="mm/dd/yyyy"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2Email ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={this.state.guardianInfo2.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="email"
                  className="form-control w-100"
                  id="guardianInfo2Email"
                  ref="guardianInfo2Email"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2address1 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 1</label>
                <input
                  value={this.state.guardianInfo2.street_address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 1"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfo2address1"
                  ref="guardianInfo2address1"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2address2 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 2</label>
                <input
                  value={this.state.guardianInfo2.street_address_2}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 2"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfo2address2"
                  ref="guardianInfo2address2"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2Gender ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Gender</label>
                <input
                  value={guardianInfo2.gender}
                  placeholder="Gender"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="guardianInfo2Gender"
                  ref="guardianInfo2Gender"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2city ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={this.state.guardianInfo2.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="guardianInfo2city"
                  ref="guardianInfo2city"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2state ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={guardianInfo2.state}
                  type="text"
                  placeholder="State"
                  className="form-control"
                  id="guardianInfo2state"
                  ref="guardianInfo2state"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2zipcode ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={this.state.guardianInfo2.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="guardianInfo2zipcode"
                  ref="guardianInfo2zipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2cellPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Cell Phone</label>
                <input
                  value={this.state.guardianInfo2.cell_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfo2cellPhone"
                  id="guardianInfo2cellPhone"
                  className="form-control"
                  placeholder="Cell Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2workPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Work Phone</label>
                <input
                  value={this.state.guardianInfo2.work_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfo2workPhone"
                  id="guardianInfo2workPhone"
                  className="form-control"
                  placeholder="Work Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guardianInfo2homePhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Home Phone</label>
                <input
                  value={this.state.guardianInfo2.home_phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guardianInfo2homePhone"
                  id="guardianInfo2homePhone"
                  className="form-control"
                  placeholder="Home Phone"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.guardianInfo2 && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="guardianInfo2"
                id="guardianInfo2Update"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="clientAddress"
          className={`demographic-component-widget ${
            this.state.component.clientAddress ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Client Address:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.address1 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 1</label>
                <input
                  value={this.state.clientAddress.street_address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 1"
                  type="text"
                  className="form-control w-100"
                  id="clientAddressAddress1"
                  ref="clientAddressAddress1"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.address2 ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 2</label>
                <input
                  value={this.state.clientAddress.street_address_2}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 2"
                  type="text"
                  className="form-control w-100"
                  id="clientAddressAddress2"
                  ref="clientAddressAddress2"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.city ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={this.state.clientAddress.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="clientAddressCity"
                  ref="clientAddressCity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.state ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={this.state.clientAddress.state}
                  placeholder="State"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="clientAddressAddressState"
                  ref="clientAddressAddressState"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.zipcode ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={this.state.clientAddress.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="clientAddressAddressZipcode"
                  ref="clientAddressAddressZipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.clientAddress && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="clientAddress"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="personalInfo"
          className={`demographic-component-widget ${
            this.state.component.personalInfo ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Personal Info:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.gender ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Gender</label>
                <input
                  value={personalInfo.gender}
                  placeholder="Gender"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="personalInfoGender"
                  ref="personalInfoGender"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.genderIdentity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Gender Identity</label>
                <input
                  value={personalInfo.gender_identity}
                  placeholder="Gender Identity"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="genderIdentity"
                  ref="genderIdentity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.sexualOrientation ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Sexual Orientation</label>
                <input
                  value={personalInfo.sexual_orientation}
                  placeholder="Sexual Orientation"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="sexualOrientation"
                  ref="sexualOrientation"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.race ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Race</label>
                <input
                  value={personalInfo.race}
                  placeholder="Race"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="race"
                  ref="race"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.ethnicity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Ethnicity</label>
                <input
                  value={personalInfo.ethnicity || ""}
                  placeholder="Ethnicity"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="ethnicity"
                  ref="ethnicity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.language ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Language</label>
                <input
                  value={personalInfo.language || ""}
                  placeholder="Language"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="language"
                  ref="language"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.personalInfo && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="personalInfo"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="primaryContactInfo"
          className={`demographic-component-widget ${
            this.state.component.primaryContactInfo ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Primary Contact Info:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={primaryContactInfo.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="primaryContactFirstname"
                  ref="primaryContactFirstname"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={primaryContactInfo.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="primaryContactLastname"
                  ref="primaryContactLastname"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactRelationship
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Relationship</label>
                <input
                  value={primaryContactInfo.relationship}
                  placeholder="Relationship"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="primaryContactRelationship"
                  ref="primaryContactRelationship"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactdob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={primaryContactInfo.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Date Of Birth"
                  type="text"
                  className="form-control w-100"
                  id="primaryContactdob"
                  ref="primaryContactdob"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactAddress ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address</label>
                <input
                  value={primaryContactInfo.address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address"
                  type="text"
                  className="form-control w-100"
                  id="primaryContactAddress"
                  ref="primaryContactAddress"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactCity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={primaryContactInfo.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="primaryContactCity"
                  ref="primaryContactCity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactState ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={primaryContactInfo.state}
                  placeholder="State"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="primaryContactState"
                  ref="primaryContactState"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactzipcode ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={primaryContactInfo.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="primaryContactzipcode"
                  ref="primaryContactzipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Phone</label>
                <input
                  value={primaryContactInfo.phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="primaryContactPhone"
                  id="primaryContactPhone"
                  className="form-control"
                  placeholder="Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.primaryContactEmail ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={primaryContactInfo.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="email"
                  className="form-control w-100"
                  id="primaryContactEmail"
                  ref="primaryContactEmail"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.primaryContactInfo && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="primaryContactInfo"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="additionalClientAddress"
          className={`demographic-component-widget ${
            this.state.component.additionalClientAddress ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Additional Client Address:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddress1
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 1</label>
                <input
                  value={additionalClientInfo.street_address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 1"
                  type="text"
                  className="form-control w-100"
                  id="additionalClientAddress1"
                  ref="additionalClientAddress1"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddress2
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address 2</label>
                <input
                  value={additionalClientInfo.street_address_2}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address 2"
                  type="text"
                  className="form-control w-100"
                  id="additionalClientAddress2"
                  ref="additionalClientAddress2"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddressCity
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={additionalClientInfo.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="additionalClientAddressCity"
                  ref="additionalClientAddressCity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddressState
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={additionalClientInfo.state}
                  placeholder="State"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="additionalClientAddressState"
                  ref="additionalClientAddressState"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddressZipcode
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={additionalClientInfo.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="additionalClientAddressZipcode"
                  ref="additionalClientAddressZipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.additionalClientAddressPhone
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Phone</label>
                <input
                  value={additionalClientInfo.phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="additionalClientAddressPhone"
                  id="additionalClientAddressPhone"
                  className="form-control"
                  placeholder="Phone"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.additionalClientAddress &&
              this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="additionalClientAddress"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="emergencyContactInfo"
          className={`demographic-component-widget ${
            this.state.component.emergencyContactInfo ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Emergency (Secondary) Contact Info:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={emergencyContactInfo.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="emergencyContactInfoFirstname"
                  ref="emergencyContactInfoFirstname"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={emergencyContactInfo.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="emergencyContactInfoLast"
                  ref="emergencyContactInfoLast"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoRelationship
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Relationship</label>
                <input
                  value={emergencyContactInfo.relationship}
                  placeholder="Relationship"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="emergencyContactInfoRelationship"
                  ref="emergencyContactInfoRelationship"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfodob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={emergencyContactInfo.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Date Of Birth"
                  type="text"
                  className="form-control w-100"
                  id="emergencyContactInfodob"
                  ref="emergencyContactInfodob"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoAddress
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address</label>
                <input
                  value={emergencyContactInfo.address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address"
                  type="text"
                  className="form-control w-100"
                  id="emergencyContactInfoAddress"
                  ref="emergencyContactInfoAddress"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoCity
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={emergencyContactInfo.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="emergencyContactInfoCity"
                  ref="emergencyContactInfoCity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoState
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={emergencyContactInfo.state}
                  placeholder="State"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="emergencyContactInfoState"
                  ref="emergencyContactInfoState"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfozipcode
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={emergencyContactInfo.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="emergencyContactInfozipcode"
                  ref="emergencyContactInfozipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoPhone
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Phone</label>
                <input
                  value={emergencyContactInfo.phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="emergencyContactInfoPhone"
                  id="emergencyContactInfoPhone"
                  className="form-control"
                  placeholder="Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.emergencyContactInfoEmail
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={emergencyContactInfo.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="email"
                  className="form-control w-100"
                  id="emergencyContactInfoEmail"
                  ref="emergencyContactInfoEmail"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.emergencyContactInfo && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="emergencyContactInfo"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>

        <div
          id="guarantor"
          className={`demographic-component-widget ${
            this.state.component.guarantor ? "show" : "d-none"
          }`}
        >
          <hr />
          <h3 className="bold">Guarantor:</h3>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.component.guarantorFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">First Name</label>
                <input
                  value={guarantorInfo.firstname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="First Name"
                  type="text"
                  className="form-control w-100"
                  id="guarantorFirstName"
                  ref="guarantorFirstName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorFirstandlastName
                  ? "show"
                  : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Last Name</label>
                <input
                  value={guarantorInfo.lastname}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Last Name"
                  type="text"
                  className="form-control w-100"
                  id="guarantorlastName"
                  ref="guarantorlastName"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorRelationship ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Relationship</label>
                <input
                  value={guarantorInfo.relationship}
                  placeholder="Relationship"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="guarantorRelationship"
                  ref="guarantorRelationship"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorGender ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Gender</label>
                <input
                  value={guarantorInfo.gender}
                  placeholder="Gender"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="guarantorGender"
                  ref="guarantorGender"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantordob ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Date Of Birth</label>
                <input
                  value={guarantorInfo.dob}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Date Of Birth"
                  type="text"
                  className="form-control w-100"
                  id="guarantordob"
                  ref="guarantordob"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorAddress ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Street Address</label>
                <input
                  value={guarantorInfo.address}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Street Address"
                  type="text"
                  className="form-control w-100"
                  id="guarantorAddress"
                  ref="guarantorAddress"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorCity ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">City</label>
                <input
                  value={guarantorInfo.city}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="City"
                  type="text"
                  className="form-control w-100"
                  id="guarantorCity"
                  ref="guarantorCity"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorState ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">State</label>
                <input
                  value={guarantorInfo.state}
                  placeholder="State"
                  onChange={(event) => this.onChangeEvent(event)}
                  className="form-control"
                  id="guarantorState"
                  ref="guarantorState"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorzipcode ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Zip Code</label>
                <input
                  value={guarantorInfo.zipcode}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="99999"
                  maskChar={null}
                  className="form-control w-100"
                  id="guarantorzipcode"
                  ref="guarantorzipcode"
                  placeholder="Zip Code"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorPhone ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Phone</label>
                <input
                  value={guarantorInfo.phone}
                  onChange={(event) => this.onChangeEvent(event)}
                  mask="+1\ 999 999 9999"
                  maskChar=" "
                  type="tel"
                  name="guarantorPhone"
                  id="guarantorPhone"
                  className="form-control"
                  placeholder="Phone"
                  readOnly
                />
              </div>
            </div>
            <div
              className={`col-md-4 ${
                this.state.component.guarantorEmail ? "show" : "d-none"
              }`}
            >
              <div className="form-group">
                <label className="control-label bold">Email</label>
                <input
                  value={guarantorInfo.email}
                  onChange={(event) => this.onChangeEvent(event)}
                  placeholder="Email"
                  type="email"
                  className="form-control w-100"
                  id="guarantorEmail"
                  ref="guarantorEmail"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div
            className={`row ${
              this.state.component.guarantor && this.state.can_edit
                ? "show"
                : "d-none"
            }`}
          >
            <div className="col-md-12">
              <span
                data-update="guarantor"
                className="demographic-info-update pointer cm-enable float-right"
                data-remote="true"
              >
                <i className="fas fa-edit fa-lg add-summary-btn"></i>
                <span className="sb-add-text">Update</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class Demographic extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Demographic Widget",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Demographic.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "demographicComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <DemographicComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
