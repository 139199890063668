import consumer from "./consumer"

consumer.subscriptions.create("AppointmentStatusAlertsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the appointments status alerts channel!")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if(data.invite_client == true) {
      if($(`#${data.subdomain}-websocket-notification`)){
        $(`#${data.subdomain}-websocket-notification`).append('<div style="box-shadow:0 5px 8px 0 rgba(0,0,0,0.2),0 3px 10px 0 rgba(0,0,0,0.19) !important;" class="position-relative text-success alert alert-warning alert-dismissible text-center fade show" role="alert"><a href="/clients/invite_clients?tab=onboard"><strong>New Client Onboarded:</strong></a><div> '+ data.patient_name + ' - ' +  'You have a new prospective client that has requested to join the practice. Please go to your Invite Client - Onboard section to view their profile.' + '</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>')
      }
    }else {
      $('#appointment_status_alerts_comp_'+data.user_guid).append('<div style="box-shadow:0 5px 8px 0 rgba(0,0,0,0.2),0 3px 10px 0 rgba(0,0,0,0.19) !important;" class="position-relative text-success alert alert-warning alert-dismissible text-center fade show" role="alert"><strong>Status Update:</strong><div> '+ data.patient_name + ' - ' + data.start_time + ' - ' + data.status +'</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>')
    }
  }
});