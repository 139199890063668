import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Udi.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';
import UdiImage from 'images/widget/Udi.png'

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const UdiComp = class extends Component { 

   constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      devices: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.devices);
  }
  getValue() {
    return this.state.devices;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        devices: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }

  openEditModal = (data) => {
    if(data.item.can_edit){
       $.post('/form_builders/widgets/new_edit_sb_attr', { 
        attr_type: 'udi',
        patient_guid: data.item.patient_guid,
        id: data.item.widgetable_id,
        patient_form_guid: data.item.patient_form_guid,
        patient_form_entry_guid: data.item.guid
      }); 
    }   
  }

  openCreateModal = () =>{
    console.log('openCreateModal')
   $.post('/form_builders/widgets/new_edit_sb_attr', {
    attr_type: 'udi',
    patient_guid: $('#patient_guid').val(), patient_form_guid: $('#patient_note_guid').val() });
  }

  render() {
    $(".component-edit-container p.lead").html("UDI Widget");
    return (
      <div id={this.state.component.key}>
        <h3>UDI</h3>
        <div className="udi-widget">
           <table className="widget-table-border w-100">
            <thead className="sb-table-header">
              <tr>
                <td>DEVICE NAME</td>
                <td>DATE IMPLEMENTED</td>
              </tr>
            </thead>
            
            <tbody className="sb-table-body-problem-widget ">
              {this.state.devices  && this.state.devices.widget_data.length > 0 && this.state.devices.widget_data.map(item => {
                  return (
                    <tr key={item.guid} id={item.guid} data-form-id={item.widgetable_id} onClick={() => this.openEditModal({item})} >
                      <td>{ item.device_name }</td>
                      <td>{ item.date_implemented }</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>
          <div className="d-flex justify-content-end">
            <span className="sb-add-udi new-sb-attr widget-component-btn" onClick={() => this.openCreateModal()} >
              <i className="fas fa-plus-circle add-summary-btn fa-lg"></i>
              <span className="sb-add-text"> New UDI</span>
            </span>
          </div>
      </div>
    );
  }
};

export default class Udi extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "UDI",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Udi.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "udiComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <UdiComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
