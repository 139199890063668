import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./HiddenFieldAssessment.settingsForm";

export default class HiddenFieldAssessment extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Hidden Field Assessment",
      icon: "minus",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: HiddenFieldAssessment.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "hiddenFieldAssessmentComp",
      hiddenFieldLabel: "",
      colorTheme: "bg-white",
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Hidden Field Assessment");



    var HiddenFieldAssessment = ''
    var attr_json = {
                      id: `${this.component.key}-hiddenFieldAssessment`,
                      class: `line-input w-100`,
                      type: 'text'
                    }
 
    var HiddenFieldAssessment = this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-hiddenFieldAssessment`,
        attr: attr_json
      }
    });

    return super.render(`
      <div id="${this.component.key}">

        <div class="form-group d-none">
          ${HiddenFieldAssessment}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}-hiddenFieldAssessment`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}-hiddenFieldAssessment`][0], 'keyup', () => this.updateValue())
    
    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}-hiddenFieldAssessment`][0].value;
    $(`input#${this.component.key}-hiddenFieldAssessment`).attr("value", value);
    return value;
  }

  setValue(value) {
    this.refs[`${this.component.key}-hiddenFieldAssessment`][0].value = value;
    $(`input#${this.component.key}-hiddenFieldAssessment`).attr("value", value);
  }

}
