import axios from "axios";
import React, { Component } from "react";

class MedicationAllergyModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.setDrugAllergies = props.setDrugAllergies;
  }

  closeModal = () => {
    this.bootstrapModal.hide();
    this.props.closeModal();
  };

  getData = (elements) => {
    let data = {};

    elements.forEach((element) => {
      data[element.name] = element.value;
    });

    return data;
  };

  handleCreateOrUpdateAllergy = (e) => {
    e.preventDefault();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    axios
      .post("/meds/create_update_med_allergies", this.getData(e.target), {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      })
      .then((res) => {
        const { selectedMedication, drugAllergies } = this.props.state;

        if (selectedMedication.id) {
          const { widget_data } = drugAllergies;
          const indexToReplace = widget_data.findIndex(
            (obj) => obj.guid === res.data.guid
          );

          if (indexToReplace !== -1) {
            widget_data[indexToReplace] = res.data;
            this.setDrugAllergies({ widget_data, nkda: false });
          } else {
            swal("Failure", "Record not found.", "error");
          }
        } else {
          if (res.data !== null) {
            const updatedWidgetData = [...drugAllergies.widget_data, res.data];
            this.setDrugAllergies({
              widget_data: updatedWidgetData,
              nkda: false,
            });
          } else {
            swal("Failure", "Something went wrong.", "error");
          }
        }
      })
      .catch(() => {
        swal("Failure", "There appears to be a server issue.", "error");
      });

    this.closeModal();
  };

  handleRemoveMedAllergy = () => {
    const { selectedMedication } = this.props.state;

    let id = selectedMedication.id;
    let patientFormGuid = selectedMedication.patient_form_guid;

    swal(
      {
        title: "Remove Medication Allergy?",
        text: "You are about to remove this allergy. Continue?",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true,
        allowOutsideClick: false,
      },
      (isConfirm) => {
        if (isConfirm) {
          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          axios
            .delete(`/summary/med_allergy/${id}`, {
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
              },
              params: {
                patient_form_guid: patientFormGuid,
              },
            })
            .then((res) => {
              const { selectedMedication, drugAllergies } = this.props.state;
              const { widget_data, nkda } = drugAllergies;

              const updatedWidgetData = widget_data.filter(
                (obj) => obj.widgetable_id !== selectedMedication.id
              );

              this.setDrugAllergies({ widget_data: updatedWidgetData, nkda });

              if (res.data.all_med_allergies_removed) {
                const drugAllergiesNKDA =
                  document.querySelector("#drugAllergiesNKDA");
                if (drugAllergiesNKDA) drugAllergiesNKDA.disabled = false;
              }

              this.closeModal();
            })

            .catch(() => {
              swal("Failure", "There appears to be a server issue.", "error");
            });
        }
      }
    );
  };

  componentDidMount() {
    const $ = window.jQuery;

    $(document).ready(() => {
      const rxPicker = (element) => {
        element.selectpicker().ajaxSelectPicker({
          ajax: {
            url: "/meds/search",
            type: "POST",
            dataType: "json",
            data: { q: "{{{q}}}" },
          },
          preprocessData: (data) => {
            var i,
              l = data.length,
              array = [];

            if (l) {
              for (i = 0; i < l; i++) {
                array.push(
                  $.extend(true, data[i], {
                    text: data[i].name,
                    value: data[i].name,
                  })
                );
              }

              addDoseOption();
            }
            array.unshift({
              name: "Add Custom Medication",
              text: "Add Custom Medication",
              value: "add_custom_medication",
            });
            return array;
          },
        });
        addMedicationOption();
      };

      $(".procedure-status-select").selectpicker("refresh");
      rxPicker($("#summaryBoxMedAllergy").find(".rx-select"));
    });

    const modalElement = this.modalRef.current;
    this.bootstrapModal = new window.bootstrap.Modal(modalElement, {
      backdrop: "static",
      keyboard: false,
    });

    this.bootstrapModal.show();
  }

  render() {
    const { selectedMedication } = this.props.state;

    return (
      <div
        className="modal"
        id="summaryBoxMedAllergy"
        data-backdrop="static"
        data-keyboard="false"
        ref={this.modalRef}
      >
        <div
          className="d-flex justify-content-center align-items-center modal-dialog"
          role="dialog"
        >
          <div className="modal-content animated fadeInUp w-100 h-100">
            <div className="modal-header text-center">
              <h2 className="px-4">
                {selectedMedication.id ? (
                  <strong>Edit Medication Allergy</strong>
                ) : (
                  <strong>Create Medication Allergy</strong>
                )}
              </h2>
              <span aria-hidden="true">
                <button
                  type="button"
                  className="close"
                  style={{ opacity: 0.8 }}
                  onClick={() => this.closeModal()}
                  aria-label="Close"
                >
                  <i
                    className="fas fa-times-circle btn-times"
                    style={{ fontSize: "1.4rem" }}
                  ></i>
                </button>
              </span>
            </div>
            <div className="modal-body p-2 h-100">
              <form
                // action="/meds/create_update_med_allergies" method="post"
                onSubmit={this.handleCreateOrUpdateAllergy}
              >
                {selectedMedication.id && (
                  <input type="hidden" name="_method" value="patch" />
                )}
                <div className="form-group px-5">
                  <label>Medication Name</label>
                  <select
                    className="w-100 rx-select"
                    data-live-search="true"
                    name="medication_name"
                    id="medication_label"
                    defaultValue={
                      selectedMedication.medication_name ||
                      "medication_name_placeholder"
                    }
                  >
                    {selectedMedication.id && (
                      <>
                        <option
                          value={selectedMedication.medication_name}
                          title={selectedMedication.medication_name}
                        >
                          {selectedMedication.medication_name}
                        </option>
                      </>
                    )}
                  </select>
                  <input
                    type="hidden"
                    name="patient_guid"
                    value={selectedMedication.patient_guid}
                  />
                  <input
                    type="hidden"
                    name="patient_form_guid"
                    value={selectedMedication.patient_form_guid}
                  />
                  <input
                    type="hidden"
                    name="id"
                    value={selectedMedication.id}
                  />
                  <input
                    type="hidden"
                    name="widget_listing_class"
                    value="show-ma-table"
                  />
                  <input
                    type="hidden"
                    name="patient_form_entry_guid"
                    value={selectedMedication.patient_form_entry_guid}
                  />
                </div>
                <div className="form-group px-5">
                  <label>Reaction</label>
                  <input
                    type="text"
                    name="reaction"
                    className="form-control"
                    defaultValue={selectedMedication.reaction || ""}
                    required
                  />
                </div>
                <div className="form-group px-5">
                  <label>Status</label>
                  <select
                    className="form-control procedure-status-select"
                    name="status"
                    defaultValue={selectedMedication.status || "active"}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <br />
                <div className="d-flex justify-content-around">
                  {selectedMedication.id && (
                    <span
                      className="btn btn-danger btn-md-wide text-center remove-sb-med-allergy"
                      data-id={selectedMedication.id}
                      onClick={this.handleRemoveMedAllergy}
                    >
                      Remove
                    </span>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary btn-md-wide text-center submit-sb-med-allergy"
                  >
                    Save
                  </button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MedicationAllergyModal;
