import React, { Component } from 'react';
import ReloadColoredImage from "images/reload.svg";
import PrintImage from "images/print.svg";

import { Recognizer } from "../vosk/src/recognizer";

class UndoRedoComponent extends Component {
  render() {
    const { handleUndo, handleRedo, canEdit, patientFormGuid, patientGuid, formGuid} = this.props;
    console.log("UndoRedoComponent", this)
    return (
      <div className="undo-redo-div d-flex pl-3">
        {canEdit == "true" ? (
          <div className="d-flex">
            <div
                className={`undo-div pointer text-center no-select`}
                onClick={handleUndo}
              >
                <img
                  src={ReloadColoredImage}
                  alt="Reload Icon"
                  height={17}
                  width={17}
                  className="reversed-image mr-1"
                />
                <small>Undo</small>
              </div>
              <div className="pt-3 text-center">
                <div className="vl-grey-short"></div>
              </div>
              <div
                className={`redo-div pointer text-center no-select`}
                onClick={handleRedo}
              >
                <img
                  src={ReloadColoredImage}
                  alt="Reload Icon"
                  height={17}
                  width={17}
                  className="mr-1"
                />
                <small>Redo</small>
              </div>
              <div className="redo-div speakButton pointer text-center no-select">
                <Recognizer />
              </div>
              
              <div>
                <span id="save-timestamp-confirmation" style={{color: "#ACAAAB", right: "28rem", position: 'absolute'}}></span>
                <button className="btn btn-primary btn-md-form mt-1 d-none" id="toolbar-save-form" type="button" >Save</button>
              </div>
          </div>
        ) : null}

        <a 
          style={{color: "#ACAAAB", right: "-1rem", position: 'absolute'}}
          className="text-primary text-secondary"
          href={`/form_builders/print_chart/${patientGuid}.pdf?type=form&form_guid=${formGuid}&patient_note_guid=${patientFormGuid}`}
          target="_blank"
          title="Print Form" 
          data-toggle="tooltip"
        >
        <img 
          width="35"
         src={PrintImage}
        />
      </a>

      </div>
    );
  }
}

export default UndoRedoComponent;
