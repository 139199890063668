import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Title (Optional)",
            weight: 1,
            key: "title",
            placeholder: "Title"
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Group Name",
            weight: 1,
            key: "groupName",
            placeholder: "Group Name",
            required: true
          },
          {
            type: "select",
            label: "Title Alignment",
            weight: 2,
            key: "titleAlign",
            data: {
              values: [
                {value: 'text-left', label: 'Left'},
                {value: 'text-center', label: 'Center'},
                {value: 'text-right', label: 'Right'}
              ]
            }
          },
          {
            label: 'Edit Column Headings and Width',
            weight: 4,
            key: 'tableColumns',
            type: 'datagrid',
            input: true,
            validate: {
              minLength: 1,
              maxLength: 10
            },
            components: [
              {
                label: 'Label',
                key: 'Label',
                type: 'textfield',
                input: true
              },
              {
                label: 'Placeholder',
                key: 'radioButton',
                type: 'textfield',
                input: true
              },
              {
                label: 'Radio Weight ',
                key: 'radioWeight',
                type: 'number',
                input: true,
                defaultValue: 1
              },
              {
                type: "select",
                label: "Column Type",
                weight: 4,
                key: "ColumnType",
                defaultValue: 'text',
                data: {
                  values: [
                    {value: 'text', label: 'Text Input'},
                    {value: 'radio', label: 'Radio Button'},
                    {value: 'checkbox', label: 'Checkbox'},
                    {value: 'label', label: 'Label'}
                  ]
                }
              },
              {
                label: 'Width',
                key: 'columnWidth',
                type: 'number',
                placeholder: '%',
                input: true
              }
            ]
          },
          {
            label: 'Edit or Add Questions',
            weight: 4,
            key: 'tableRows',
            type: 'datagrid',
            input: true,
            validate: {
              minLength: 1,
              maxLength: 100
            },
            components: [
              {
                label: 'Label',
                key: 'Label',
                type: 'textfield',
                input: true
              }
            ]
          },
          {
            type: "checkbox",
            label: "Required to save note",
            weight: 5,
            key: "requiredSaveNote",
            tooltip: "Selecting this box requires the Clinician to enter text minimally within one grid in the table before the note can be saved."
          },
          {
            type: "checkbox",
            label: "Add Tooltip",
            weight: 6,
            key: "addTooltip"
          },
          {
            type: "textfield",
            label: "",
            weight: 7,
            key: "tooltipDescription",
            placeholder: "Add a descriptor to this component of the form",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.addTooltip"
                  },
                  true
                ]
              }
            }
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
