import React from "react"
import PropTypes from "prop-types"
import { Recognizer } from "./vosk/src/recognizer";
import "antd/dist/antd.min.css";



class SpeechToText extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Recognizer />
      </React.Fragment>
    );
  }
}

SpeechToText.propTypes = {
  greeting: PropTypes.string
};
export default SpeechToText