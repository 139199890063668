import React from "react"
import PropTypes from "prop-types"
import { Recognizer } from "./vosk/src/recognizer";
import "antd/dist/antd.min.css";
import styled from "styled-components";



class HelloWorld extends React.Component {
  render () {
    return (
      <div className="container">
			<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
			  Open modal
			</button>

			<div className="modal" id="myModal">
			  <div className="modal-dialog">
			    <div className="modal-content">

			      <div className="modal-header">
			        <h4 className="modal-title">Modal Heading</h4>
			        <button type="button" className="close" data-dismiss="modal">&times;</button>
			      </div>

			      <div className="modal-body">
			        Modal body..
			      </div>

			      <div className="modal-footer">
			        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
			      </div>

			    </div>
			  </div>
			</div>
      </div>
    );
  }
}

HelloWorld.propTypes = {
  greeting: PropTypes.string
};
export default HelloWorld